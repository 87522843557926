const HomeAbout = () => {
  return (
    <section className="about_sc u_spc">
      <div className="conta_iner">
        <div className="gap_p aic">
          <div className="left_sc hd_1">
            <h2>Web3 & Real Estate</h2>
            <p>
              Web3 real estate unveils a new world of financial autonomy,
              transparency and asset ownership to people. Web3 technologies and
              concepts, like blockchain, NFTs, metaverse, and cryptocurrencies,
              can change the face of real estate. Web3 presents a decentralized
              ledger and open-source information management framework called
              blockchain for every type of transaction to be registered on. It
              streamlines data management and brings transparency, security and
              accuracy to the table.
            </p>
            <p>
              The shift from web 2.0 to web3 marks technological advancements
              that involve the development of decentralized, readily accessible,
              permanent transaction ledgers, transparent ownership documentation
              and asset tokenization protocols. Such solutions and services
              built on web3 can improve and enhance the real estate domain far
              beyond its current capabilities. They also help investors prove
              ownership, estimate risk directly and coherently without external
              hindrances, and handle liquidity.
            </p>
          </div>
          <div className="right_sc">
            <figure>
              <img src="../../images/3d-rendering-cartoon-house.jpg" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeAbout;
