import "../cms.scss";
import Layout from "../../../layout";
import { useAppSelector } from "../../../hooks/store";
import { getCms } from "../../../reducers/generalSlice";

const TermsConditions = () => {
  const getCmsData = useAppSelector(getCms);
  return (
    <Layout>
      <main>
        <section className="cms_sc ut_spc">
          <div className="conta_iner">
            <h1>Terms & Conditions</h1>
            <h2>Introduction</h2>
            <div
              className={getCmsData ? "policy_txt" : "policy_txt2"}
              dangerouslySetInnerHTML={{
                __html: getCmsData
                  ? getCmsData?.TermsandConditions
                  : "No Data Found",
              }}
            />
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default TermsConditions;
