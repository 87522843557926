const HomeMarquee = () => {
  return (
    <section className="marquee_sc">
      <div className="marquee_content">
        <span>
          A VISION FOR AFFORDABLE, GLOBAL & EASY REAL-ESTATE INVESTMENT
        </span>
        <span>
          A VISION FOR AFFORDABLE, GLOBAL & EASY REAL-ESTATE INVESTMENT
        </span>
        <span>
          A VISION FOR AFFORDABLE, GLOBAL & EASY REAL-ESTATE INVESTMENT
        </span>
      </div>
    </section>
  );
};

export default HomeMarquee;
