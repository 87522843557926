import {
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { SecondaryButton } from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import { Dispatch, SetStateAction } from "react";
import { isNumber } from "../../utils/validation";

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSink: Dispatch<SetStateAction<string>>;
  setPantry: Dispatch<SetStateAction<string>>;
  setSitting: Dispatch<SetStateAction<string>>;
  sitting: string;
  setClosets: Dispatch<SetStateAction<string>>;
  shower: string;
  setShower: Dispatch<SetStateAction<string>>;
  bar: string;
  setBar: Dispatch<SetStateAction<string>>;
  closets: string;
  formik: any;
  sink: string;
  pantry: string;
};

const Inferior = ({
  setOpen,
  formik,
  sink,
  setSink,
  pantry,
  setPantry,
  sitting,
  setSitting,
  closets,
  setClosets,
  bar,
  setBar,
  shower,
  setShower,
}: Props) => {
  const handleSinkChange = (event: SelectChangeEvent) => {
    setSink(event.target.value as string);
  };

  const handlePantryChange = (event: SelectChangeEvent) => {
    setPantry(event.target.value as string);
  };

  const handleSittingChange = (event: SelectChangeEvent) => {
    setSitting(event.target.value as string);
  };
  const handleBarChange = (event: SelectChangeEvent) => {
    setBar(event.target.value as string);
  };
  const handleShowerChange = (event: SelectChangeEvent) => {
    setShower(event.target.value as string);
  };
  const handleClosetChange = (event: SelectChangeEvent) => {
    setClosets(event.target.value as string);
  };

  return (
    <>
      <div className="flex_head">
        <h2>Interior features</h2>
        <Button className="close_btn" onClick={() => setOpen(false)}>
          <CloseIcon />
        </Button>
      </div>
      <div className="form_control">
        <TextField
          hiddenLabel
          type="text"
          placeholder="Built-In Features"
          variant="outlined"
          fullWidth
          id="builtIn"
          name="builtIn"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.builtIn}
          helperText={formik.touched.builtIn && formik.errors.builtIn}
        />
      </div>
      <div className="form_control">
        <TextField
          hiddenLabel
          type="text"
          placeholder="Bedroom Count"
          variant="outlined"
          fullWidth
          id="bedroomMainLevel"
          name="bedroomMainLevel"
          onChange={(val) => {
            if (val.target.value === " " || val.target.value === ".") {
            } else if (isNumber(val.target.value)) {
              formik.handleChange(val);
            }
          }}
          onBlur={formik.handleBlur}
          value={formik.values.bedroomMainLevel}
          helperText={
            formik.touched.bedroomMainLevel && formik.errors.bedroomMainLevel
          }
        />
      </div>
      <div className="form_control">
        <TextField
          hiddenLabel
          type="text"
          placeholder="Kitchen count"
          variant="outlined"
          fullWidth
          id="breakfastArea"
          name="breakfastArea"
          onChange={(val) => {
            if (val.target.value === " " || val.target.value === ".") {
            } else if (isNumber(val.target.value)) {
              formik.handleChange(val);
            }
          }}
          onBlur={formik.handleBlur}
          value={formik.values.breakfastArea}
          helperText={
            formik.touched.breakfastArea && formik.errors.breakfastArea
          }
        />
      </div>
      <div className="form_control">
        <TextField
          hiddenLabel
          type="text"
          placeholder="Bathroom Count"
          variant="outlined"
          fullWidth
          id="bathroom"
          name="bathroom"
          onChange={(val) => {
            if (val.target.value === " " || val.target.value === ".") {
            } else if (isNumber(val.target.value)) {
              formik.handleChange(val);
            }
          }}
          onBlur={formik.handleBlur}
          value={formik.values.bathroom}
          helperText={formik.touched.bathroom && formik.errors.bathroom}
        />
      </div>
      <div className="form_control">
        <TextField
          hiddenLabel
          type="text"
          placeholder="Dining Area(sq m)"
          variant="outlined"
          fullWidth
          id="diningArea"
          name="diningArea"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.diningArea}
          helperText={formik.touched.diningArea && formik.errors.diningArea}
        />
      </div>
      {/* <div className="form_control">
            <TextField
              hiddenLabel
              type="text"
              placeholder="Separate Formal Dining Room"
              variant="outlined"
              fullWidth
            />
          </div> */}
      <div className="form_control">
        <label>Dual Sink</label>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={sink}
          onChange={handleSinkChange}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </div>
      {/* <div className="form_control">
            <TextField
              hiddenLabel
              type="text"
              placeholder="High Ceilings"
              variant="outlined"
              fullWidth
            />
          </div> */}
      {/* <div className="form_control">
            <TextField
              hiddenLabel
              type="text"
              placeholder="Kitchen Island"
              variant="outlined"
              fullWidth
            />
          </div> */}
      <div className="form_control">
        <label>Pantry</label>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={pantry}
          onChange={handlePantryChange}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </div>
      <div className="form_control">
        <label>Sitting Area in Primary</label>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={sitting}
          onChange={handleSittingChange}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </div>
      <div className="form_control">
        <label>Separate Shower</label>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={shower}
          onChange={handleShowerChange}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </div>
      {/* <div className="form_control">
            <label>Upper Level Primary</label>
            <NativeSelect
              fullWidth
              inputProps={{
                name: "ppper-level-primary",
              }}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </NativeSelect>
          </div> */}
      <div className="form_control">
        <label>Bar</label>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={bar}
          onChange={handleBarChange}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </div>
      <div className="form_control">
        <label>Walk In Closets</label>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={closets}
          onChange={handleClosetChange}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </div>
      <div className="form_btn">
        <SecondaryButton content="Save" onClick={() => setOpen(false)} />
      </div>
    </>
  );
};

export default Inferior;
