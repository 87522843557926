import React from "react";
import "../../auth/auth.scss";
import Layout from "../../../layout";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import { EmbarkComponent, SecondaryButton } from "../../../components";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Input } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const OwnerTransferAddPropertyInfo = () => {
  const [open_profitHistory, setOpen_profitHistory] = React.useState(false);
  const handleOpen_profitHistory = () => setOpen_profitHistory(true);
  const handleClose_profitHistory = () => setOpen_profitHistory(false);

  const [open_loanDetails, setOpen_loanDetails] = React.useState(false);
  const handleOpen_loanDetails = () => setOpen_loanDetails(true);
  const handleClose_loanDetails = () => setOpen_loanDetails(false);

  const [open_interiorFeatures, setOpen_interiorFeatures] =
    React.useState(false);
  const handleOpen_interiorFeatures = () => setOpen_interiorFeatures(true);
  const handleClose_interiorFeatures = () => setOpen_interiorFeatures(false);

  const [open_appliances, setOpen_appliances] = React.useState(false);
  const handleOpen_appliances = () => setOpen_appliances(true);
  const handleClose_appliances = () => setOpen_appliances(false);

  const [open_buildingConstruction, setOpen_buildingConstruction] =
    React.useState(false);
  const handleOpen_buildingConstruction = () =>
    setOpen_buildingConstruction(true);
  const handleClose_buildingConstruction = () =>
    setOpen_buildingConstruction(false);

  const [open_other, setOpen_other] = React.useState(false);
  const handleOpen_other = () => setOpen_other(true);
  const handleClose_other = () => setOpen_other(false);

  const [age_Elevator, setAge_Elevator] = React.useState("");

  const handleChange_Elevator = (event: SelectChangeEvent) => {
    setAge_Elevator(event.target.value);
  };

  return (
    <Layout>
      <main>
        <section className="auth_page">
          <div className="side_container">
            <div className="gap_p">
              <EmbarkComponent />
              <div className="right_side">
                <h2>Add Property Info</h2>
                <form action="/owner-transfer-upload-picture-video">
                  <div className="form_control">
                    <RadioGroup
                      className="flex"
                      aria-labelledby="demo-document-label"
                      defaultValue="valid-passport"
                      name="document"
                    >
                      <FormControlLabel
                        className="label_checkbox"
                        value="profit-sharing"
                        control={<Radio />}
                        label="Profit Sharing"
                      />
                      <FormControlLabel
                        className="label_checkbox"
                        value="ownership-transfer"
                        control={<Radio />}
                        label="Ownership Transfer"
                      />
                    </RadioGroup>
                  </div>
                  <div>
                    <div className="form_control">
                      <label>Total Amount of property</label>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type="text"
                        placeholder="Total Amount of property"
                        fullWidth
                        name="total-amount-property"
                        variant="outlined"
                      />
                    </div>
                    <div className="form_control">
                      <label>Milestone 1 (Upfront Amount)</label>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type="text"
                        placeholder="Milestone 1 (Upfront Amount)"
                        fullWidth
                        name="milestone1"
                        variant="outlined"
                      />
                    </div>
                    <div className="form_control">
                      <label>Milestone 2 (Contract Signing)</label>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type="text"
                        placeholder="Milestone 2 (Contract Signing)"
                        fullWidth
                        name="milestone2"
                        variant="outlined"
                      />
                    </div>
                    <div className="form_control">
                      <label>Milestone 3 (Ownership contract minting)</label>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type="text"
                        placeholder="Milestone 3 (Ownership contract minting)"
                        fullWidth
                        name="milestone3"
                        variant="outlined"
                      />
                    </div>
                    <div className="form_control">
                      <label>
                        <h6>Upload Document</h6>
                      </label>
                      <div className="gap_p">
                        <div className="upload_document v2 w_50">
                          <label htmlFor="icon-picture1">
                            <div className="inner">
                              <AddIcon />
                              <Input
                                id="icon-picture1"
                                type="file"
                                inputProps={{
                                  accept: "image/png,image/jpeg",
                                }}
                              />
                            </div>
                          </label>
                        </div>
                        <div className="upload_document v2 w_50">
                          <label htmlFor="icon-picture2">
                            <div className="inner">
                              <AddIcon />
                              <Input
                                id="icon-picture2"
                                type="file"
                                inputProps={{
                                  accept: "image/png,image/jpeg",
                                }}
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form_control">
                    <span
                      className="modalClick"
                      onClick={handleOpen_profitHistory}
                    >
                      Profit History <ArrowForwardIosIcon />{" "}
                    </span>
                  </div>
                  <div className="form_control">
                    <span
                      className="modalClick"
                      onClick={handleOpen_loanDetails}
                    >
                      Loan Details (If any) <ArrowForwardIosIcon />
                    </span>
                  </div>
                  <div className="form_control">
                    <span
                      className="modalClick"
                      onClick={handleOpen_interiorFeatures}
                    >
                      Interior features <ArrowForwardIosIcon />
                    </span>
                  </div>
                  <div className="form_control">
                    <span
                      className="modalClick"
                      onClick={handleOpen_appliances}
                    >
                      Appliances <ArrowForwardIosIcon />
                    </span>
                  </div>
                  <div className="form_control">
                    <span
                      className="modalClick"
                      onClick={handleOpen_buildingConstruction}
                    >
                      Building and Construction <ArrowForwardIosIcon />
                    </span>
                  </div>
                  <div className="form_control">
                    <span className="modalClick" onClick={handleOpen_other}>
                      Other <ArrowForwardIosIcon />
                    </span>
                  </div>
                  <div className="form_btn">
                    <SecondaryButton content="Next" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        {/* Profit History */}
        <Modal
          className="modal form_modal"
          open={open_profitHistory}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open_profitHistory}>
            <Box className="modalBox_body">
              <Box>
                <div className="flex_head">
                  <h2>Profit History</h2>
                  <Button
                    className="close_btn"
                    onClick={handleClose_profitHistory}
                  >
                    <CloseIcon />
                  </Button>
                </div>
                <form>
                  <div className="form_control">
                    <div className="flex_label">
                      <label>Profit History</label>
                      <span className="add">
                        Add New <AddIcon />
                      </span>
                    </div>
                    <div className="gap_p">
                      <div>
                        <label>Date</label>
                        <TextField
                          hiddenLabel
                          type="text"
                          placeholder="Date"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                      <div>
                        <label>Profit</label>
                        <TextField
                          hiddenLabel
                          type="text"
                          placeholder="Profit"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                      <div>
                        <label>Address</label>
                        <TextField
                          hiddenLabel
                          type="text"
                          placeholder="Address"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form_btn">
                    <SecondaryButton content="Save" />
                  </div>
                </form>
              </Box>
            </Box>
          </Fade>
        </Modal>

        {/* Loan Details */}
        <Modal
          className="modal form_modal"
          open={open_loanDetails}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open_loanDetails}>
            <Box className="modalBox_body">
              <Box>
                <div className="flex_head">
                  <h2>Loan Details (if any)</h2>
                  <Button
                    className="close_btn"
                    onClick={handleClose_loanDetails}
                  >
                    <CloseIcon />
                  </Button>
                </div>
                <form>
                  <div className="form_control">
                    <div className="flex_label">
                      <label>Loan Details(If any)</label>
                    </div>
                    <div className="gap_p">
                      <div>
                        <label>Bank Name</label>
                        <TextField
                          hiddenLabel
                          type="text"
                          placeholder="Bank Name"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                      <div>
                        <label>Loan Type</label>
                        <TextField
                          hiddenLabel
                          type="text"
                          placeholder="Loan Type"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                      <div>
                        <label>Amount</label>
                        <TextField
                          hiddenLabel
                          type="text"
                          placeholder="Amount"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                      <div>
                        <label>Paid Amount</label>
                        <TextField
                          hiddenLabel
                          type="text"
                          placeholder="Paid Amount"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form_btn">
                    <SecondaryButton content="Save" />
                  </div>
                </form>
              </Box>
            </Box>
          </Fade>
        </Modal>

        {/* Interior Features */}
        <Modal
          className="modal form_modal"
          open={open_interiorFeatures}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open_interiorFeatures}>
            <Box className="modalBox_body">
              <Box>
                <div className="flex_head">
                  <h2>Interior Features</h2>
                  <Button
                    className="close_btn"
                    onClick={handleClose_interiorFeatures}
                  >
                    <CloseIcon />
                  </Button>
                </div>
                <form>
                  <div className="form_control">
                    <label>Built-In Features</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Built-In Features"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>Bedroom Main Level</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Bedroom Main Level"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>Breakfast Area</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Breakfast Area"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>Dining Area</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Dining Area"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>Separate Formal Dining Room</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Separate Formal Dining Room"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>Dual Sinks</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Dual Sinks"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>Elevator</label>
                    <Select
                      fullWidth
                      value={age_Elevator}
                      onChange={handleChange_Elevator}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  </div>
                  <div className="form_btn">
                    <SecondaryButton content="Save" />
                  </div>
                </form>
              </Box>
            </Box>
          </Fade>
        </Modal>

        {/* Appliances */}
        <Modal
          className="modal form_modal"
          open={open_appliances}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open_appliances}>
            <Box className="modalBox_body">
              <Box>
                <div className="flex_head">
                  <h2>Appliances</h2>
                  <Button
                    className="close_btn"
                    onClick={handleClose_appliances}
                  >
                    <CloseIcon />
                  </Button>
                </div>
                <form>
                  <div className="form_control">
                    <label>Dishwasher</label>
                    <Select
                      fullWidth
                      value={age_Elevator}
                      onChange={handleChange_Elevator}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  </div>
                  <div className="form_control">
                    <label>Dryer</label>
                    <Select
                      fullWidth
                      value={age_Elevator}
                      onChange={handleChange_Elevator}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  </div>
                  <div className="form_control">
                    <label>Ice Maker</label>
                    <Select
                      fullWidth
                      value={age_Elevator}
                      onChange={handleChange_Elevator}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  </div>
                  <div className="form_control">
                    <label>Microwave</label>
                    <Select
                      fullWidth
                      value={age_Elevator}
                      onChange={handleChange_Elevator}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  </div>
                  <div className="form_control">
                    <label>Oven</label>
                    <Select
                      fullWidth
                      value={age_Elevator}
                      onChange={handleChange_Elevator}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  </div>
                  <div className="form_control">
                    <label>Range</label>
                    <Select
                      fullWidth
                      value={age_Elevator}
                      onChange={handleChange_Elevator}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  </div>
                  <div className="form_control">
                    <label>Refrigerator</label>
                    <Select
                      fullWidth
                      value={age_Elevator}
                      onChange={handleChange_Elevator}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  </div>
                  <div className="form_control">
                    <label>Washer</label>
                    <Select
                      fullWidth
                      value={age_Elevator}
                      onChange={handleChange_Elevator}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  </div>
                  <div className="form_control">
                    <label>Heating: Central Furnace</label>
                    <Select
                      fullWidth
                      value={age_Elevator}
                      onChange={handleChange_Elevator}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  </div>
                  <div className="form_control">
                    <label>
                      <h6>Exterior and lot features</h6>
                    </label>
                    <ul className="value_list">
                      <li>Balcony</li>
                      <li>Security High Impact Doors</li>
                      <li>
                        <AddIcon />
                      </li>
                    </ul>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Exterior and lot features"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>
                      <h6>Parking features</h6>
                    </label>
                    <ul className="value_list">
                      <li>Balcony</li>
                      <li>Security High Impact Doors</li>
                      <li>
                        <AddIcon />
                      </li>
                    </ul>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Parking features"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_btn">
                    <SecondaryButton content="Save" />
                  </div>
                </form>
              </Box>
            </Box>
          </Fade>
        </Modal>

        {/* Building and Construction */}
        <Modal
          className="modal form_modal"
          open={open_buildingConstruction}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open_buildingConstruction}>
            <Box className="modalBox_body">
              <Box>
                <div className="flex_head">
                  <h2>Building and Construction</h2>
                  <Button
                    className="close_btn"
                    onClick={handleClose_buildingConstruction}
                  >
                    <CloseIcon />
                  </Button>
                </div>
                <form>
                  <div className="form_control">
                    <label>Year built</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Type here..."
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>Construction materials</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Type here..."
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>Flooring</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Type here..."
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>Roof</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Type here..."
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>Architectural style</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Type here..."
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_btn">
                    <SecondaryButton content="Save" />
                  </div>
                </form>
              </Box>
            </Box>
          </Fade>
        </Modal>

        {/* Other */}
        <Modal
          className="modal form_modal"
          open={open_other}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open_other}>
            <Box className="modalBox_body">
              <Box>
                <div className="flex_head">
                  <h2>Other</h2>
                  <Button className="close_btn" onClick={handleClose_other}>
                    <CloseIcon />
                  </Button>
                </div>
                <form>
                  <div className="form_control">
                    <label>View</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Water"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>Waterfront</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="yes"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>Waterfront features</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Ocean Access"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>Accessibility features</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Accessible Elevator Installed"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>Taxes</label>
                    <Select
                      fullWidth
                      value={age_Elevator}
                      onChange={handleChange_Elevator}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  </div>
                  <div className="form_btn">
                    <SecondaryButton content="Save" />
                  </div>
                </form>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </main>
    </Layout>
  );
};

export default OwnerTransferAddPropertyInfo;
