import LandingPage from "./landingPage";
import Login from "./auth/login";
import ForgotPassword from "./auth/forgotPassword";
import ResetPassword from "./auth/resetPassword";
import ChangePassword from "./auth/changePassword";
import CreateAccount from "./auth/createAccount";
import UploadDocunment from "./auth/uploadDocument";
import Verification from "./auth/verification";
import Profile from "./auth/profile";
import AddPropertyInfo from "./addProperty/profitSharing/addPropertyInfo";
import UploadPictureAndVideo from "./addProperty/profitSharing/upload";
import StakeMonthsProfit from "./addProperty/profitSharing/stake";
import OwnerTransferAddPropertyInfo from "./addProperty/ownershipTransfer/addPropertyInfo";
import OwnerTransferUploadPictureAndVideo from "./addProperty/ownershipTransfer/upload";
import PropertyDetail from "./addProperty/profitSharing/PropertyDetail";
import OwnershipTransferPropertyDetail from "./addProperty/ownershipTransfer/PropertyDetail";
import PropertyMarket from "./Properties";
import PropertyLocation from "./propertiesLocation";
import Transactions from "./transactions";
import MyProperties from "./myProperties";
import BlogDetail from "./blogs/detail/blogDetail";
import BlogListing from "./blogs/listing/blogListing";
import PrivacyPolicy from "./cms/privacyPolicy/privacyPolicy";
import TermsConditions from "./cms/termsConditions/termsConditions";
import ContactUs from "./cms/contactUs/contactUs";
import FavProperty from "./favProperties.tsx";
import AboutUs from "./cms/aboutUs/aboutUs";
import NewsDetail from "./news/detail/newsDetail";
import NewsListing from "./news/listing/newsListing";
import ViewProfile from "./auth/profile/features/viewProfile";
import EditDocuments from "./auth/profile/features/editDocuments";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LandingPage,
  Login,
  ForgotPassword,
  ResetPassword,
  ChangePassword,
  CreateAccount,
  UploadDocunment,
  Verification,
  Profile,
  AddPropertyInfo,
  UploadPictureAndVideo,
  StakeMonthsProfit,
  OwnerTransferAddPropertyInfo,
  OwnerTransferUploadPictureAndVideo,
  PropertyDetail,
  OwnershipTransferPropertyDetail,
  PropertyMarket,
  PropertyLocation,
  Transactions,
  MyProperties,
  BlogListing,
  BlogDetail,
  PrivacyPolicy,
  TermsConditions,
  ContactUs,
  FavProperty,
  AboutUs,
  NewsDetail,
  NewsListing,
  ViewProfile,
  EditDocuments,
};
