type props = {
  content: string;
  onClick?: () => void;
};
const SecondaryButton = ({ content, onClick }: props) => {
  return (
    <button type="submit" className="btn btn_secondary" onClick={onClick}>
      {content}
    </button>
  );
};

export default SecondaryButton;
