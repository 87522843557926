import React, { useState } from "react";
import "../../auth/auth.scss";
import Layout from "../../../layout";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import EmbarkComponent from "../../../components/Embark";
import SecondaryButton from "../../../components/button/secondaryButton";

const StakeMonthsProfit = () => {
  const navigate = useNavigate();

  const [open_successModal, setOpen_successModal] = useState(false);

  const handleOpen_successModal = () => {
    setOpen_successModal(true);
    setTimeout(() => {
      setOpen_successModal(false);
      navigate("/");
    }, 3000);
  };

  return (
    <Layout>
      <main>
        <section className="auth_page">
          <div className="side_container">
            <div className="gap_p">
              <EmbarkComponent />
              <div className="right_side">
                <h2>Stake 2 Months Profit</h2>
                {/* <form action=""> */}
                {/* <div className="form_control">
                                        <label>Add Amount</label>
                                        <TextField
                                            className="text_field"
                                            hiddenLabel
                                            type="text"
                                            placeholder="USD 200"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </div> */}
                <div className="form_control">
                  <ul className="stake_info">
                    <li>
                      <span>USD 9.62 /Share × 20</span>
                      <span>USD 192.39</span>
                    </li>
                    <li>
                      <strong>Total</strong>
                      <strong>USD 192.39</strong>
                    </li>
                  </ul>
                </div>
                <div className="form_btn">
                  <SecondaryButton
                    content="Pay Now"
                    onClick={handleOpen_successModal}
                  />
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </section>

        {/* Success Modal */}
        <Modal
          className="modal success_modal"
          open={open_successModal}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open_successModal}>
            <Box className="modalBox_body">
              <Box>
                <Button
                  className="close_btn"
                  onClick={() => setOpen_successModal(false)}
                >
                  <CloseIcon />
                </Button>
                <figure>
                  <img src="../../images/success-tick.svg" alt="" />
                </figure>
                <h2>Awesome !</h2>
                <p>
                  "Admin will approve your property request in 2 to 3 business
                  days.
                </p>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </main>
    </Layout>
  );
};

export default StakeMonthsProfit;
