import Layout from "../../layout";
import "./myProperties.scss";
import MyPropertiesTab from "./features/propertiesTab";

const MyProperties = () => {
  return (
    <Layout>
      <main>
        <MyPropertiesTab />
      </main>
    </Layout>
  );
};

export default MyProperties;
