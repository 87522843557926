/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useLazyGetPurchasedPropertyQuery } from "../../../service/Property";
import { GetPropertyDetail } from "../../../types/General";
import { Loader, errorToast } from "../../../helpers";
import useAuth from "../../../hooks/useAuth";
import PropertyGrid from "../../../components/propertyGrid";
import { useNavigate } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyBoughtProperties = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [favItems, setFavItems] = useState<GetPropertyDetail[]>([]);
  const [purchaseProperty, { isLoading }] = useLazyGetPurchasedPropertyQuery();

  const [propertyDetail, setPropertyDetail] = React.useState<
    GetPropertyDetail[]
  >([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getAllPurchasedProperties = async (id: string) => {
    try {
      const response = await purchaseProperty({
        investment_type: value === 0 ? "Profit Sharing" : "Ownership",
      }).unwrap();
      if (response?.statusCode === 200) {
        setPropertyDetail(response?.data?.property || []);
      } else {
        setPropertyDetail([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (userData) {
      getAllPurchasedProperties(userData?._id as string);
    }
  }, [value, userData]);

  return (
    <>
      <Loader isLoad={isLoading} />
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        className="sub_tab"
      >
        <Tab label="Profit Sharing" {...a11yProps(0)} />
        {/* <Tab label="Ownership" {...a11yProps(1)} /> */}
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <div className="v2_grid">
          {propertyDetail?.length ? (
            <PropertyGrid
              propertyDetail={propertyDetail}
              status
              bought
              favItems={favItems}
              setFavItems={setFavItems}
            />
          ) : (
            <div className="no_property">
              <figure>
                <img src="/images/noproperty.png" alt="" />
              </figure>
              <h3>No properties found</h3>
              <p>
                Get started by{" "}
                <span onClick={() => navigate("/add-property")}>
                  adding new one
                </span>
              </p>
            </div>
          )}

          {/* <ul className="property_grid gap_p">
            {propertyDetail?.length ? (
              propertyDetail?.map((item) => {
                return (
                  <li
                    key={item?._id}
                    onClick={() => navigate(`/property-detail/${item?._id}`)}
                  >
                    <div className="single_property">
                      <figure>
                        <p
                          style={{
                            background:
                              item?.propertyStatus === "APPROVED"
                                ? "green"
                                : "red",
                          }}
                        >
                          {item?.propertyStatus || ""}
                        </p>
                        <img
                          src={
                            item?.propertyImages?.[0] ||
                            "../../images/property-01.jpg"
                          }
                          alt=""
                        />
                      </figure>
                      <div className="flex">
                        <p>
                          No of Tokens on sale : {item?.totalTokens || 0}{" "}
                          <small>
                            Per Token Price : USDT {item?.tokenValue || 0}
                          </small>
                        </p>
                        <span>{item?.propertyType || ""}</span>
                      </div>
                      <h4>{item?.propertyTitle || ""}</h4>
                      <p>
                        {item?.propertyAddress}, {item?.country} -{" "}
                        <span>{item?.investmentType || ""}</span>
                      </p>
                    </div>
                  </li>
                );
              })
            ) : (
              <li
                className="no_pro_img"
                style={{ width: "100%", textAlign: "center" }}
              >
                <figure>
                <img src="/images/no_property.jpg" alt="" />
              </figure>
                <p style={{ width: "100%" }}>
                  You have not listed any property yet!
                </p>
              </li>
            )}
          </ul> */}
        </div>
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={1}>
        <div className="v2_grid">
          <ul className="property_grid gap_p">
            <li>
              <div className="single_property">
                <figure>
                  <img src="../../images/property-01.jpg" alt="" />
                </figure>
                <h3>423,00</h3>
                <h4>Luxury villa in Range Park</h4>
                <p>
                  California, CA, USA - <span>Ownership</span>
                </p>
                <ul>
                  <li>
                    <img src="../../images/icon_bed.svg" alt="" /> 3 Bed
                  </li>
                  <li>
                    <img src="../../images/icon_bath.svg" alt="" /> 2 Bath
                  </li>
                  <li>
                    <img src="../../images/icon_kitchen.svg" alt="" /> 1 Kitchen
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className="single_property">
                <figure>
                  <img src="../../images/property-01.jpg" alt="" />
                </figure>
                <h3>423,00</h3>
                <h4>Luxury villa in Range Park</h4>
                <p>
                  California, CA, USA - <span>Ownership</span>
                </p>
                <ul>
                  <li>
                    <img src="../../images/icon_bed.svg" alt="" /> 3 Bed
                  </li>
                  <li>
                    <img src="../../images/icon_bath.svg" alt="" /> 2 Bath
                  </li>
                  <li>
                    <img src="../../images/icon_kitchen.svg" alt="" /> 1 Kitchen
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className="single_property">
                <figure>
                  <img src="../../images/property-01.jpg" alt="" />
                </figure>
                <h3>423,00</h3>
                <h4>Luxury villa in Range Park</h4>
                <p>
                  California, CA, USA - <span>Ownership</span>
                </p>
                <ul>
                  <li>
                    <img src="../../images/icon_bed.svg" alt="" /> 3 Bed
                  </li>
                  <li>
                    <img src="../../images/icon_bath.svg" alt="" /> 2 Bath
                  </li>
                  <li>
                    <img src="../../images/icon_kitchen.svg" alt="" /> 1 Kitchen
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className="single_property">
                <span className="tag_active"></span>
                <figure>
                  <img src="../../images/property-01.jpg" alt="" />
                </figure>
                <h3>423,00</h3>
                <h4>Luxury villa in Range Park</h4>
                <p>
                  California, CA, USA - <span>Ownership</span>
                </p>
                <ul>
                  <li>
                    <img src="../../images/icon_bed.svg" alt="" /> 3 Bed
                  </li>
                  <li>
                    <img src="../../images/icon_bath.svg" alt="" /> 2 Bath
                  </li>
                  <li>
                    <img src="../../images/icon_kitchen.svg" alt="" /> 1 Kitchen
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className="single_property">
                <figure>
                  <img src="../../images/property-01.jpg" alt="" />
                </figure>
                <h3>423,00</h3>
                <h4>Luxury villa in Range Park</h4>
                <p>
                  California, CA, USA - <span>Ownership</span>
                </p>
                <ul>
                  <li>
                    <img src="../../images/icon_bed.svg" alt="" /> 3 Bed
                  </li>
                  <li>
                    <img src="../../images/icon_bath.svg" alt="" /> 2 Bath
                  </li>
                  <li>
                    <img src="../../images/icon_kitchen.svg" alt="" /> 1 Kitchen
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className="single_property">
                <figure>
                  <img src="../../images/property-01.jpg" alt="" />
                </figure>
                <h3>423,00</h3>
                <h4>Luxury villa in Range Park</h4>
                <p>
                  California, CA, USA - <span>Ownership</span>
                </p>
                <ul>
                  <li>
                    <img src="../../images/icon_bed.svg" alt="" /> 3 Bed
                  </li>
                  <li>
                    <img src="../../images/icon_bath.svg" alt="" /> 2 Bath
                  </li>
                  <li>
                    <img src="../../images/icon_kitchen.svg" alt="" /> 1 Kitchen
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className="single_property">
                <figure>
                  <img src="../../images/property-01.jpg" alt="" />
                </figure>
                <h3>423,00</h3>
                <h4>Luxury villa in Range Park</h4>
                <p>
                  California, CA, USA - <span>Ownership</span>
                </p>
                <ul>
                  <li>
                    <img src="../../images/icon_bed.svg" alt="" /> 3 Bed
                  </li>
                  <li>
                    <img src="../../images/icon_bath.svg" alt="" /> 2 Bath
                  </li>
                  <li>
                    <img src="../../images/icon_kitchen.svg" alt="" /> 1 Kitchen
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className="single_property">
                <span className="tag_active"></span>
                <figure>
                  <img src="../../images/property-01.jpg" alt="" />
                </figure>
                <h3>423,00</h3>
                <h4>Luxury villa in Range Park</h4>
                <p>
                  California, CA, USA - <span>Ownership</span>
                </p>
                <ul>
                  <li>
                    <img src="../../images/icon_bed.svg" alt="" /> 3 Bed
                  </li>
                  <li>
                    <img src="../../images/icon_bath.svg" alt="" /> 2 Bath
                  </li>
                  <li>
                    <img src="../../images/icon_kitchen.svg" alt="" /> 1 Kitchen
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </CustomTabPanel> */}
    </>
  );
};

export default MyBoughtProperties;
