/* eslint-disable react-hooks/exhaustive-deps */
import Routing from "./Routes";
import "./App.scss";
import { useAppDispatch } from "./hooks/store";
import { useEffect } from "react";
import { useLazyGetCmsListQuery } from "./service/Common";
import { resetCms } from "./reducers/generalSlice";

function App() {
  const dispatch = useAppDispatch();
  const [getCmsMethod] = useLazyGetCmsListQuery();

  const getCmsData = async () => {
    try {
      const res = await getCmsMethod({}).unwrap();
      if (res?.statusCode === 200) {
        dispatch(resetCms({ cms: res?.data || null }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCmsData();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return <Routing />;
}

export default App;
