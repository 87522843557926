/* eslint-disable react-hooks/exhaustive-deps */
import "../blog.scss";
import Layout from "../../../layout";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Loader, errorToast } from "../../../helpers";
import { Blog } from "../../../types/General";
import { useLazyGetBlogByIdQuery } from "../../../service/Common";
import moment from "moment";

const BlogDetail = () => {
  const { id } = useParams();
  const [blogDetail, setBlogDetail] = useState<Blog>();
  const [blogDetailMutation, { isLoading }] = useLazyGetBlogByIdQuery();

  const getBlogDetail = async () => {
    try {
      const response = await blogDetailMutation({
        blog_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setBlogDetail(response?.data[0]);
      }
    } catch (error: any) {
      console.log(error, "errror");
      errorToast(error?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      getBlogDetail();
    }
  }, []);

  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <main className="blogDetail_page">
        <section className="blogDetail_sc ut_spc">
          <div className="conta_iner">
            <div className="inner">
              <figure className="blog_figure">
                <img src={blogDetail?.blogImages || ""} alt="" />
              </figure>
              <p className="rounded">
                {moment(blogDetail?.createdAt).format("ll")}
              </p>
              <h1>{blogDetail?.title || ""}</h1>
              <div className="blog_desc">
                <p style={{ wordWrap: "break-word" }}>
                  {blogDetail?.description || ""}
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default BlogDetail;
