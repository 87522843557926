import React from "react";
import "../../auth/auth.scss";
import Layout from "../../../layout";
import CreateAccountContent from "./features/createAccount";

const CreateAccount = () => {
  return (
    <Layout>
      <main>
        <CreateAccountContent />
      </main>
    </Layout>
  );
};

export default CreateAccount;
