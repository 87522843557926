/* eslint-disable react-hooks/exhaustive-deps */
import "../cms.scss";
import Layout from "../../../layout";
import { AboutUsRes, useLazyGetAboutUsQuery } from "../../../service/Common";
import { useEffect, useState } from "react";

const AboutUs = () => {
  const [aboutUs] = useLazyGetAboutUsQuery();
  const [abousUsData, setAboutUsData] = useState<AboutUsRes>();

  const handleAboutUs = async () => {
    try {
      const res = await aboutUs({}).unwrap();
      if (res?.statusCode === 200) {
        setAboutUsData(res?.data[0]);
      }
    } catch (error) {
      console.log(error, "eroro");
    }
  };

  useEffect(() => {
    handleAboutUs();
  }, []);

  return (
    <Layout>
      <main className="about_page">
        <section className="about_page_sc ut_spc">
          <div className="conta_iner">
            <h1>
              Through tokenization, we envision an <br />
              inclusive world where affordable real estate ownership <br />
              becomes a reality for all.
              {/* <span className="d_block">
                ownership becomes a reality for all.
              </span> */}
            </h1>
            <p>
              As a result of technology, we envision a world where real estate{" "}
              <span className="d_block">
                transactions are "self-driving" and REALTORS®
              </span>{" "}
              <span className="d_block">are empowered.</span>
            </p>
          </div>
          <figure>
            <img src="/images/about-banner-image.png" alt="" />
          </figure>
        </section>

        <section className="about_story_sc ut_spc">
          <div className="conta_iner">
            <div className="text_center hd_1 head_mb">
              <h2>
                <span>OUR STORY</span>
              </h2>
            </div>
            <div className="gap_p">
              <div className="left_s">
                <figure>
                  <img src="/images/about-image.jpg" alt="" />
                </figure>
              </div>
              <div className="right_s">
                <p>
                  Block Estate was born from a shared vision to revolutionize
                  real estate through blockchain technology. Our journey began
                  with a passion for innovation and a deep understanding of the
                  real estate industry. We saw an opportunity to bring
                  transparency, accessibility, and security to real estate
                  transactions, making them simpler and fairer for everyone
                  involved. Our commitment to innovation drives us to constantly
                  improve and expand our services. Today, we stand as a beacon
                  of trust in the real estate market, using blockchain to create
                  unalterable records of property transactions. We believe in
                  making property opportunities accessible to all, breaking down
                  barriers, and ensuring data security. Our community of users
                  and partners is at the heart of our success, and we continue
                  to work closely with them to enhance our platform. As we move
                  forward, we are excited about the future and the opportunities
                  to redefine real estate for a better tomorrow. Join us on this
                  exciting journey as we continue to reshape the world of real
                  estate with blockchain technology
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="about_mission_sc ut_spc">
          <div className="conta_iner">
            <div className="text_center hd_1">
              <h2>
                <span>OUR MISSION</span>
              </h2>
              <p>
                Welcome to Block Estate, where our mission is to democratize
                real estate investment globally. We're tokenizing real estate to
                reduce financial barriers, making it accessible for everyone.
                Join us as we showcase how we're turning this mission into a
                reality.
              </p>
            </div>
          </div>
        </section>

        <section className="about_team_sc ut_spc">
          <div className="conta_iner">
            <div className="text_center hd_1 head_mb">
              <h2>
                <span>OUR TEAM</span>
              </h2>
            </div>
            <div className="team_grid">
              <div className="gap_m">
                {abousUsData?.team?.length
                  ? abousUsData?.team?.map((item, i) => (
                      <div className="single_team">
                        <figure>
                          <img src={item?.imagePath || ""} alt="" />
                        </figure>
                        <p>
                          <strong>{item?.name || "--"}</strong>
                          <span>{item?.Designation || "--"}</span>
                        </p>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default AboutUs;
