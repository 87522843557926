/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import PropertyGrid from "../../../components/propertyGrid";
import { useGetAllfavPropertyMutation } from "../../../service/Property";
import { errorToast } from "../../../helpers";
import { useEffect, useState } from "react";
import { GetPropertyDetail } from "../../../types/General";

const HomeFavoritedProperties = () => {
  const [favProperty] = useGetAllfavPropertyMutation();
  const [propertyDetail, setPropertyDetail] = useState<GetPropertyDetail[]>([]);
  const [favItems, setFavItems] = useState<GetPropertyDetail[]>([]);

  const getFavProperty = async () => {
    try {
      const response = await favProperty({}).unwrap();
      if (response?.statusCode === 200) {
        setPropertyDetail(response?.data?.property);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
      console.log(error);
    }
  };

  useEffect(() => {
    getFavProperty();
  }, []);

  const checkFavProperties = () => {
    const fav_data = propertyDetail
      .filter((item: GetPropertyDetail) => item?.isFavourite)
      .map((item: any) => item);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProperties();
  }, [propertyDetail]);

  return (
    <>
      {propertyDetail?.length ? (
        <section className="propertiesList_sc u_spc">
          <div className="conta_iner">
            <div className="text_center hd_1 mb_60">
              <h2>Most Favorited Properties</h2>
            </div>

            <PropertyGrid
              propertyDetail={propertyDetail.slice(0, 6)}
              favItems={favItems}
              setFavItems={setFavItems}
            />
            {propertyDetail?.length > 6 ? (
              <div className="text_center">
                <Link to="/favourite-properties" className="btn_link">
                  See All
                </Link>
              </div>
            ) : null}
          </div>
        </section>
      ) : null}
    </>
  );
};

export default HomeFavoritedProperties;
