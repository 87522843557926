import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { EmbarkComponent, SecondaryButton } from "../../../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { usePostChangePasswordMutation } from "../../../../service/Auth";
import { Loader, errorToast, successToast } from "../../../../helpers";
import { useNavigate } from "react-router";

const ChangePasswordContent = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [changePasswordMethod, changePasswordMutation] =
    usePostChangePasswordMutation();

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .label("password")
        .required("Password is required.")
        .label("Password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Must contain 8 or more characters, one uppercase, one lowercase and one number. "
        ),
      newPassword: Yup.string()
        .label("new Password")
        .required("New password is required.")
        .min(6, "Password must be at least 6 characters.")
        .notOneOf(
          [Yup.ref("password"), null],
          "New password cannot be same as old password."
        )
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Must contain 8 or more characters, one uppercase, one lowercase and one number. "
        ),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("newPassword"), undefined], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      let body = {
        oldPassword: formik.values.password,
        password: formik.values.newPassword,
      };

      try {
        const res = await changePasswordMethod(body).unwrap();
        if (res?.statusCode === 200) {
          successToast("Password changed successfully" || "");
          navigate("/", { replace: true });
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }

      formik.setSubmitting(false);
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl = document.getElementById("password");
    if (inputEl) {
      setTimeout(() => {
        if (inputEl instanceof HTMLInputElement) {
          inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
        }
      }, 0);
    }
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
    const inputEl = document.getElementById("newPassword");
    if (inputEl) {
      setTimeout(() => {
        if (inputEl instanceof HTMLInputElement) {
          inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
        }
      }, 0);
    }
  };

  const handleClickConfirmShowPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
    const inputEl = document.getElementById("passwordConfirmation");

    if (inputEl) {
      setTimeout(() => {
        if (inputEl instanceof HTMLInputElement) {
          inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
        }
      }, 0);
    }
  };

  const handleMouseDownPassword = (e: any) => {
    e.preventDefault();
  };

  return (
    <section className="auth_page">
      <Loader isLoad={changePasswordMutation?.isLoading} />
      <div className="side_container">
        <div className="gap_p">
          <EmbarkComponent />
          <div className="right_side">
            <h2>Change Password</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="form_control">
                <TextField
                  className="text_field"
                  hiddenLabel
                  placeholder="Current Password"
                  fullWidth
                  name="password"
                  id="password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  error={Boolean(
                    formik.touched.password && formik.errors.password
                  )}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment className="eye_btn" position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {!showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form_control">
                <TextField
                  className="text_field"
                  hiddenLabel
                  placeholder="New Password"
                  fullWidth
                  name="newPassword"
                  id="newPassword"
                  variant="outlined"
                  type={showNewPassword ? "text" : "password"}
                  error={Boolean(
                    formik.touched.newPassword && formik.errors.newPassword
                  )}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment className="eye_btn" position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {!showNewPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form_control">
                <TextField
                  className="text_field"
                  hiddenLabel
                  placeholder="Confirm Password"
                  fullWidth
                  name="passwordConfirmation"
                  id="passwordConfirmation"
                  variant="outlined"
                  type={showConfirmPassword ? "text" : "password"}
                  error={Boolean(
                    formik.touched.passwordConfirmation &&
                      formik.errors.passwordConfirmation
                  )}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordConfirmation}
                  helperText={
                    formik.touched.passwordConfirmation &&
                    formik.errors.passwordConfirmation
                  }
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment className="eye_btn" position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickConfirmShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {!showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form_btn">
                <SecondaryButton content="Change Password" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangePasswordContent;
