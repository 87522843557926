/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Blog } from "../../../types/General";
import { useLazyGetBlogsQuery } from "../../../service/Common";
import { errorToast } from "../../../helpers";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";

const HomeBlog = () => {
  const navigate = useNavigate();
  const [getBlog] = useLazyGetBlogsQuery();
  const [blogs, setBlogs] = useState<Blog[]>([]);

  const getAllBlog = async () => {
    try {
      const response = await getBlog({}).unwrap();
      if (response?.statusCode === 200) {
        setBlogs(response?.data?.blogs);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBlog();
  }, []);

  return (
    <>
      {blogs?.length ? (
        <section className="blog_sc u_spc" id="blog">
          <div className="conta_iner">
            <div className="hd_1 mb_60">
              <h2>Trending Blog</h2>
            </div>
            <div className="blogs_list">
              {blogs?.length ? (
                blogs?.slice(0, 2)?.map((item) => (
                  <article
                    className="single"
                    key={item?._id}
                    onClick={() => navigate(`/blog-detail/${item?._id}`)}
                  >
                    <figure>
                      <img src={item?.blogImages} alt="" />
                    </figure>
                    <div className="blog_info">
                      <p className="rounded">
                        {moment(item?.createdAt).format("ll")}
                      </p>
                      <h3>{item?.title || ""}</h3>
                      <p style={{ wordWrap: "break-word" }}>
                        {item?.description?.length >= 310
                          ? item?.description?.slice(0, 310) + "...."
                          : item?.description}
                      </p>
                    </div>
                  </article>
                ))
              ) : (
                <p>No blogs found</p>
              )}
            </div>
          </div>
          {blogs?.length > 2 ? (
            <div className="text_center" style={{ marginTop: 50 }}>
              <Link to="/blog-listing" className="btn_link">
                See All
              </Link>
            </div>
          ) : null}
        </section>
      ) : null}
    </>
  );
};

export default HomeBlog;
