import "../cms.scss";
import Layout from "../../../layout";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { SecondaryButton } from "../../../components";
import { useAppSelector } from "../../../hooks/store";
import { getCms } from "../../../reducers/generalSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useState } from "react";
import { usePostQueryMutation } from "../../../service/Common";
import { Loader, errorToast, successToast } from "../../../helpers";
import { isString } from "../../../utils/validation";

const ContactUs = () => {
  const getCmsData = useAppSelector(getCms);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [postQuery, { isLoading }] = usePostQueryMutation();

  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phone: "",
      query: "",
      subject: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required"),
      query: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required"),
      subject: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required"),
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        email: formik.values.email,
        phone: formik.values.phone,
        name: formik.values.fullName,
        message: formik.values.query,
        countryCode: phoneCode,
        subject: formik.values.subject,
      };
      console.log("body: ", body);
      try {
        const response = await postQuery(body).unwrap();
        if (response?.statusCode === 200) {
          successToast(
            "Query sent successfully. Admin will revert within 2-3 working days on your email"
          );
          formik.resetForm();
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error);
      }
    },
  });
  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <main>
        <section className="contact_sc ut_spc">
          <div className="conta_iner">
            <div className="gap_p">
              <div className="left-side">
                <h1>Get in touch</h1>
                <p>
                  At BlockEstate , we're here to help you with all your real
                  estate needs. Whether you're buying, selling, renting, or just
                  looking for expert advice, our team of experienced real estate
                  professionals is ready to assist you every step of the way.
                  Please don't hesitate to get in touch with us using the
                  contact information below, or simply fill out the contact
                  form, and we'll reach out to you promptly.
                </p>
                <h2>Address</h2>
                <p>
                  3rd Floor, C-127, Phase-8, Industrial Area, Sector 73 <br />
                  Sahibzada Ajit Singh Nagar, Punjab 160071
                </p>
                <div className="gap_p gap_y_0">
                  <div className="w_50">
                    <h2>Phone Number</h2>
                    <p>
                      <Link to={`tel:${getCmsData?.phone || ""}`}>
                        {getCmsData?.countryCode
                          ? (getCmsData?.countryCode.includes("+") ? "" : "+") +
                            getCmsData?.countryCode
                          : null}{" "}
                        {getCmsData?.phone || "--"}
                      </Link>
                    </p>
                  </div>
                  <div className="w_50">
                    <h2>Email</h2>
                    <p>
                      <Link to={`mailto:${getCmsData?.email || ""}`}>
                        {getCmsData?.email || ""}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="right-side">
                <form onSubmit={formik.handleSubmit}>
                  <div className="form_control">
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder="Full Name"
                      fullWidth
                      name="fullName"
                      variant="outlined"
                      id="fullName"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.fullName}
                      helperText={
                        formik.touched.fullName && formik.errors.fullName
                      }
                    />
                  </div>
                  <div className="form_control">
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="email"
                      placeholder="Email"
                      fullWidth
                      name="email"
                      variant="outlined"
                      id="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </div>
                  <div className="form_control">
                    <PhoneInput
                      enableSearch={true}
                      value={phoneCode + formik.values.phone}
                      country={"us"}
                      inputClass="phoneInput"
                      dropdownClass="phone_drpdwn"
                      buttonClass="phoneBtn"
                      placeholder="Phone Number"
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form_control">
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder="Subject"
                      fullWidth
                      variant="outlined"
                      id="subject"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.subject}
                      helperText={
                        formik.touched.subject && formik.errors.subject
                      }
                    />
                  </div>
                  <div className="form_control">
                    <TextField
                      className="text_field"
                      hiddenLabel
                      multiline
                      rows={5}
                      type="text"
                      placeholder="Message"
                      fullWidth
                      variant="outlined"
                      id="query"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.query}
                      helperText={formik.touched.query && formik.errors.query}
                    />
                  </div>
                  <div className="form_btn">
                    <SecondaryButton content="Submit" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default ContactUs;
