import "../../auth/auth.scss";
import Layout from "../../../layout";
import LoginContent from "./features/login";

const Login = () => {
  return (
    <Layout>
      <main>
        <LoginContent />
      </main>
    </Layout>
  );
};

export default Login;
