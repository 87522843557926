/* eslint-disable react-hooks/exhaustive-deps */
import { Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useState } from "react";
import { PropertiesByCity } from "../../../types/General";
import { useGetAllPropertiesWithCountryMutation } from "../../../service/Property";
import { Skeleton } from "@mui/material";

const HomeProperties = () => {
  const navigate = useNavigate();
  const [getPropertyByName] = useGetAllPropertiesWithCountryMutation();
  const [getProperty, setGetProperty] = useState<PropertiesByCity[]>([]);

  const getAllPropertiesData = async () => {
    try {
      const res = await getPropertyByName({}).unwrap();
      if (res?.statusCode === 200) {
        setGetProperty(res?.data?.propertiesByCity || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPropertiesData();
  }, []);

  // let uniqueObjects: { [key: string]: (typeof getProperty)[0] } = {};

  // for (let obj of getProperty) {
  //   // Create a unique key based on "name" and "img"
  //   let key = obj.country;

  //   // If the key doesn't exist in uniqueObjects, add the object
  //   if (!uniqueObjects[key]) {
  //     uniqueObjects[key] = obj;
  //   }
  // }

  // Extract the values (unique objects) from the uniqueObjects object
  // let uniqueArr = Object.values(uniqueObjects);

  return (
    <>
      {getProperty?.length ? (
        <section
          className="property_sc uht_spc ub_spc"
          style={{ overflow: "hidden" }}
        >
          <div className="conta_iner">
            <div className="text_center hd_1 mb_60">
              <h2>Properties by cities</h2>
            </div>

            <Swiper
              className="property_swiper"
              dir="rtl"
              modules={[Autoplay, Navigation]}
              spaceBetween={0}
              slidesPerView={"auto"}
              // centeredSlides={true}
              // centeredSlidesBounds={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              navigation
              loop={true}
            >
              {getProperty &&
                getProperty?.map((item) => (
                  <SwiperSlide>
                    <div
                      className="single_slide"
                      onClick={() =>
                        navigate("/property-location", {
                          state: item?.city,
                        })
                      }
                    >
                      {item ? (
                        <figure>
                          <img
                            src={
                              item?.property?.propertyImages?.length
                                ? item?.property?.propertyImages[0]
                                : ""
                            }
                            alt=""
                          />
                          <figcaption>{item?.city || ""}</figcaption>
                          <div className="slide_overlay">
                            <h3>{item?.city || ""}</h3>
                          </div>
                        </figure>
                      ) : (
                        <Skeleton
                          variant="rounded"
                          width="100%"
                          height="100%"
                          animation="wave"
                        />
                      )}

                      {/* <p>10 Properties</p> */}
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default HomeProperties;
