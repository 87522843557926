import { END_POINTS } from "../helpers";
import {
  GetAllCountryPropertyRes,
  GetPropertyDetail,
  PropertydividentRes,
  Transaction,
  TransactionRes,
  addOwnershipPropertyBody,
} from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  data?: User;
  message: string;
};

type AddPropertyBody = {
  propertyTitle: string;
  propertyDescription: string;
  totalTokens: string;
  propertyType: string;
  investmentType: string;
  expectedProfit: string;
};

type updateProfitSharingBody = {
  propertyImages: any;
  imageDescription: string;
  propertyVideos: any;
  videoDescription: string;
};

type OwnershipRes = {
  propertyTitle: string;
  propertyDescription: string;
  propertyPrice: string;
  milestoneAmount1: string;
  milestoneAmount2: string;
  milestoneAmount3: string;
  propertyType: string;
  investmentType: string;
  ownershipDocuments: string[];
  profitHistory: {
    Date: string;
    Profit: number;
    address: string;
  }[];
  BankName: string;
  LoanType: string;
  amount: number;
  paidAmount: number;
  BuiltInFeature: string;
  diningArea: string;
  bedroomMainLevel: string;
  BreakfastArea: string;
  dualSink: string;
  pantry: string;
  sittingArea: string;
  Bar: string;
  separateShower: string;
  walkInClosets: string;
  dishwasher: string;
  dryer: string;
  refrigerator: string;
  washingMachine: string;
  microwave: string;
  Oven: string;
  heatingCentralFurnace: string;
  YearBuilt: string;
  ContructionMeterial: string;
  Flooring: string;
  Roof: string;
  ArchitecturalStyle: string;
  View: string;
  WaterFront: string;
  WaterFrontFeature: string;
  AccessibilityFeature: string;
  Taxes: string;
};

type GetTokenParams = {
  search: string;
  bathrooms: string | number;
  bedroom: string | number;
  location: string[];
  propertyType: string[];
};

type GetPropertyRes = {
  propertyCount: number;
  property: GetPropertyDetail[];
};

type TransactionHistory = {
  transaction: TransactionRes[];
  transactionCount: number;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addProfitProperty: builder.mutation<
      CommonResponseType & { data: User },
      AddPropertyBody
    >({
      query: (body) => ({
        url: END_POINTS.add_property,
        method: "POST",
        body,
      }),
    }),
    addOwnershipProperty: builder.mutation<
      CommonResponseType & { data: OwnershipRes },
      addOwnershipPropertyBody
    >({
      query: (body) => ({
        url: END_POINTS.add_owner_property,
        method: "POST",
        body,
      }),
    }),

    updateProfitProperty: builder.mutation<
      { message: string; statusCode: number; data: User },
      { property_id: string | undefined; body: updateProfitSharingBody }
    >({
      query: ({ property_id, body }) => ({
        url: `${END_POINTS.update_profitSharing}/${property_id}/`,
        method: "PUT",
        body,
      }),
    }),
    getPropertyDetailById: builder.query<
      {
        message: string;
        statusCode: number;
        data: {
          property: GetPropertyDetail[];
          propertyData: PropertydividentRes[];
        };
      },
      { property_id: string | undefined; month: string }
    >({
      query: ({ property_id, month }) => ({
        url: `${END_POINTS.get_property_byId}/${property_id}?month=${month}`,
        method: "GET",
      }),
    }),
    getAllPropByInvest: builder.query<
      CommonResponseType & {
        data: { propertyCount: number; property: GetPropertyDetail[] };
      },
      { investment_type: string; user_Id: string }
    >({
      query: ({ investment_type, user_Id }) => ({
        url: `${END_POINTS.get_prop_by_inves}/${user_Id}?search=${investment_type}`,
        method: "GET",
      }),
    }),
    getPurchasedProperty: builder.query<
      CommonResponseType & {
        data: { propertyCount: number; property: GetPropertyDetail[] };
      },
      { investment_type: string }
    >({
      query: ({ investment_type }) => ({
        url: `${END_POINTS.getPurchasedPropertiesByUser}?search=${investment_type}`,
        method: "GET",
      }),
    }),
    getAllProperty: builder.mutation<
      CommonResponseType & { data: GetPropertyRes },
      GetTokenParams
    >({
      query: ({ search, bedroom, bathrooms, location, propertyType }) => ({
        url: `${END_POINTS.get_all_property}?search=${search}&bedrooms=${bedroom}&bathrooms=${bathrooms}&location=${location}&propertyType=${propertyType}`,
        method: "GET",
      }),
    }),
    getAllPropertiesWithCountry: builder.mutation<
      CommonResponseType & {
        data: GetAllCountryPropertyRes;
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.getAllPropertiesWithCountry,
        method: "GET",
      }),
    }),
    getAllPropertyByCity: builder.query<
      CommonResponseType & { data: GetPropertyRes },
      { property_city: string }
    >({
      query: ({ property_city }) => ({
        url: `${END_POINTS.get_property_by_city}/${property_city}`,
        method: "GET",
      }),
    }),
    changeFavStatus: builder.mutation<
      CommonResponseType & { data: GetPropertyDetail },
      { property_id: string | undefined }
    >({
      query: ({ property_id }) => ({
        url: `${END_POINTS.add_fav}/${property_id}/`,
        method: "PUT",
      }),
    }),
    getAllfavProperty: builder.mutation<
      CommonResponseType & { data: GetPropertyRes },
      {}
    >({
      query: () => ({
        url: END_POINTS.get_fav_prop,
        method: "GET",
      }),
    }),
    getDividend: builder.mutation<
      CommonResponseType & { data: string },
      { property_id: string | undefined; month: string }
    >({
      query: ({ property_id, month }) => ({
        url: `${END_POINTS.userDividendByProperty}/${property_id}?month=${month}`,
        method: "POST",
      }),
    }),
    buyToken: builder.mutation<
      CommonResponseType & { data: GetPropertyRes },
      { body: { tokenAmounts: string }; property_id: string | undefined }
    >({
      query: ({ property_id, body }) => ({
        url: `${END_POINTS.buy_token}/${property_id}`,
        method: "POST",
        body,
      }),
    }),
    claimProfit: builder.mutation<
      CommonResponseType & { data: GetPropertyRes },
      { body: { profitAmount: string }; property_id: string | undefined }
    >({
      query: ({ body, property_id }) => ({
        url: `${END_POINTS.claimProfit}/${property_id}`,
        method: "POST",
        body,
      }),
    }),
    soldToken: builder.mutation<
      CommonResponseType & { data: GetPropertyRes },
      { body: { soldTokens: string } }
    >({
      query: (body) => ({
        url: `${END_POINTS.soldTokens}`,
        method: "POST",
        body,
      }),
    }),
    setTokenOnSale: builder.mutation<
      CommonResponseType & { data: GetPropertyRes },
      { body: { onSellTokens: string }; property_id: string | undefined }
    >({
      query: ({ property_id, body }) => ({
        url: `${END_POINTS.setTokensOnSell}/${property_id}`,
        method: "PUT",
        body,
      }),
    }),
    getTransaction: builder.query<
      {
        message: string;
        statusCode: number;
        data: Transaction;
      },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.getTransactionBYUser}`,
        method: "GET",
      }),
    }),
    transactionHistory: builder.query<
      {
        message: string;
        statusCode: number;
        data: TransactionHistory;
      },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.transactionHistory}`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useAddProfitPropertyMutation,
  useUpdateProfitPropertyMutation,
  useAddOwnershipPropertyMutation,
  useLazyGetPropertyDetailByIdQuery,
  useLazyGetAllPropByInvestQuery,
  useGetAllPropertyMutation,
  useChangeFavStatusMutation,
  useGetAllfavPropertyMutation,
  useLazyGetAllPropertyByCityQuery,
  useBuyTokenMutation,
  useLazyGetPurchasedPropertyQuery,
  useGetAllPropertiesWithCountryMutation,
  useLazyGetTransactionQuery,
  useLazyTransactionHistoryQuery,
  useClaimProfitMutation,
  useSoldTokenMutation,
  useSetTokenOnSaleMutation,
  useGetDividendMutation,
} = authApi;
