import Layout from "../../layout";
import "../Properties/propertyListing.scss";
import PropertyMarketListing from "./features/propertyListing";

const PropertyMarket = () => {
  return (
    <Layout>
      <main>
        <PropertyMarketListing />
      </main>
    </Layout>
  );
};

export default PropertyMarket;
