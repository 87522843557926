import React, { Dispatch, SetStateAction } from "react";
import {
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { SecondaryButton } from "../../components";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setTax: Dispatch<SetStateAction<string>>;
  tax: string;
  formik: any;
};

const Other = ({ setOpen, formik, setTax, tax }: Props) => {
  return (
    <>
      <div className="flex_head">
        <h2>Other</h2>
        <Button className="close_btn" onClick={() => setOpen(false)}>
          <CloseIcon />
        </Button>
      </div>
      <div className="form_control">
        <label>View</label>
        <TextField
          hiddenLabel
          type="text"
          placeholder="Water"
          variant="outlined"
          fullWidth
          id="view"
          name="view"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.view}
          helperText={formik.touched.view && formik.errors.view}
        />
      </div>
      <div className="form_control">
        <label>Waterfront</label>
        <TextField
          hiddenLabel
          type="text"
          placeholder="yes"
          variant="outlined"
          fullWidth
          id="viewFront"
          name="viewFront"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.viewFront}
          helperText={formik.touched.viewFront && formik.errors.viewFront}
        />
      </div>
      <div className="form_control">
        <label>Waterfront features</label>
        <TextField
          hiddenLabel
          type="text"
          placeholder="Ocean Access"
          variant="outlined"
          fullWidth
          id="viewFeature"
          name="viewFeature"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.viewFeature}
          helperText={formik.touched.viewFeature && formik.errors.viewFeature}
        />
      </div>
      <div className="form_control">
        <label>Accessibility features</label>
        <TextField
          hiddenLabel
          type="text"
          placeholder="Accessible Elevator Installed"
          variant="outlined"
          fullWidth
          id="viewAccessiblity"
          name="viewAccessiblity"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.viewAccessiblity}
          helperText={
            formik.touched.viewAccessiblity && formik.errors.viewAccessiblity
          }
        />
      </div>
      <div className="form_control">
        <label>Taxes</label>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={tax}
          onChange={(event: SelectChangeEvent) => {
            setTax(event.target.value as string);
          }}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </div>
      <div className="form_btn">
        <SecondaryButton content="Save" onClick={() => setOpen(false)} />
      </div>
    </>
  );
};

export default Other;
