import React from "react";
import "../../auth/auth.scss";
import Layout from "../../../layout";
import ForgotPasswordContent from "./features/forgotPassword";

const ForgotPassword = () => {
    return (
        <Layout>
            <main>
                <ForgotPasswordContent />
            </main>
        </Layout>
    );
};

export default ForgotPassword;
