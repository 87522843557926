import React, { ChangeEvent, useEffect, useState } from "react";
import SecondaryButton from "../../../../components/button/secondaryButton";
import { Input } from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
import "react-phone-input-2/lib/bootstrap.css";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetProfileQuery,
  usePutUpdateProfileMutation,
} from "../../../../service/Auth";
import Layout from "../../../../layout";
import { errorToast } from "../../../../helpers";
import { UploadMedia } from "../../../../utils/mediaUpload";

const EditDocuments = () => {
  const userData = useAuth();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [frontImg, setFrontImg] = useState<string>("");
  const [backImg, setBackImg] = useState<string>("");

  const [updateProfileMethod] = usePutUpdateProfileMutation();
  const [getProfileMethod] = useLazyGetProfileQuery();

  const handleImageUpload = async (
    event: ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    try {
      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        if (key === "front") {
          setFrontImg(res?.data);
        } else {
          setBackImg(res?.data);
        }
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProfileData = async () => {
    try {
      await getProfileMethod({}).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userData) {
      setBackImg(userData?.documentBackImage || "");
      setFrontImg(userData?.documentFrontImage || "");
    }
  }, [userData]);

  const submitKYCDoc = async () => {
    setError(true);
    if (!frontImg || !backImg) {
      return;
    }
    let body = {
      documentFrontImage: frontImg,
      documentBackImage: backImg,
      userStatus: "PENDING",
      reDocumentVerfication: "true",
    };

    try {
      const response = await updateProfileMethod(body).unwrap();
      if (response?.statusCode === 200) {
        navigate("/view-profile", { replace: true });
        getProfileData();
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  return (
    <Layout>
      <main>
        <section className="auth_page ut_spc">
          <div className="conta_iner">
            <div className="right_side small_width">
              <h3>Upload Document for KYC</h3>
              <div className="form_control">
                <div className="gap_p">
                  {/* <div className="upload_document w_50">
              <label htmlFor="icon-button-file-front" className="inner">
                {frontImg ? (
                  <>
                    <div className="cross_icn">
                      <CloseIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          setFrontImg("");
                        }}
                      />
                    </div>
                    <figure>
                      <img src={frontImg} alt="" />
                    </figure>
                  </>
                ) : (
                  <>
                    <span>
                      <img src="../../images/icon_upload.svg" alt="" />
                      Drop your files here
                    </span>
                    <Input
                      sx={{ display: "none" }}
                      id="icon-button-file-front"
                      type="file"
                      inputProps={{
                        accept: "image/png,image/jpeg",
                      }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleImageUpload(e, "front")
                      }
                    />
                  </>
                )}
              </label>
              {error && !frontImg ? (
                <h6 className="err_msg">This field is required</h6>
              ) : null}
            </div> */}
                  <div className="upload_document w_50">
                    {frontImg ? (
                      <div className="inner">
                        <div className="cross_icn">
                          <CloseIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              setFrontImg("");
                            }}
                          />
                        </div>

                        <figure>
                          <img src={frontImg} alt="" />
                        </figure>
                      </div>
                    ) : (
                      <label htmlFor="icon-button-file-front">
                        <div className="inner">
                          <span>
                            <img src="../../images/icon_upload.svg" alt="" />
                            Drag & Drop your files here
                          </span>
                          <Input
                            id="icon-button-file-front"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleImageUpload(e, "front")}
                          />
                        </div>

                        {error && !frontImg ? (
                          <h6 className="err_msg">This field is required</h6>
                        ) : null}
                      </label>
                    )}
                  </div>
                  <div className="upload_document w_50">
                    {backImg ? (
                      <div className="inner">
                        <div className="cross_icn">
                          <CloseIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              setBackImg("");
                            }}
                          />
                        </div>

                        <figure>
                          <img src={backImg} alt="" />
                        </figure>
                      </div>
                    ) : (
                      <label htmlFor="icon-button-file-back">
                        <div className="inner">
                          <span>
                            <img src="../../images/icon_upload.svg" alt="" />
                            Drag & Drop your files here
                          </span>
                          <Input
                            id="icon-button-file-back"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleImageUpload(e, "back")}
                          />
                        </div>

                        {error && !backImg ? (
                          <h6 className="err_msg">This field is required</h6>
                        ) : null}
                      </label>
                    )}
                  </div>
                  {/* <div className="upload_document w_50">
              <label htmlFor="icon-button-file-back" className="inner">
                {backImg ? (
                  <>
                    <div className="cross_icn">
                      <CloseIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          setBackImg("");
                        }}
                      />
                    </div>
                    <figure>
                      <img src={backImg} alt="" />
                    </figure>
                  </>
                ) : (
                  <>
                    <span>
                      <img src="../../images/icon_upload.svg" alt="" />
                      Drag & Drop your files here
                    </span>
                    <Input
                      sx={{ display: "none" }}
                      id="icon-button-file-back"
                      type="file"
                      inputProps={{
                        accept: "image/png,image/jpeg",
                      }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleImageUpload(e, "back")
                      }
                    />
                  </>
                )}
              </label>
              {error && !backImg ? (
                <h6 className="err_msg">This field is required</h6>
              ) : null}
            </div> */}
                </div>
              </div>
              <div className="form_btn">
                <SecondaryButton content="Submit" onClick={submitKYCDoc} />
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default EditDocuments;
