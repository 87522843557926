import React, { Dispatch, SetStateAction } from "react";
import { Button, TextField } from "@mui/material";
import { SecondaryButton } from "../../components";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  formik: any;
};

const Construction = ({ setOpen, formik }: Props) => {
  return (
    <>
      <div className="flex_head">
        <h2>Building and Construction</h2>
        <Button className="close_btn" onClick={() => setOpen(false)}>
          <CloseIcon />
        </Button>
      </div>
      <div className="form_control">
        <label>Year built</label>
        <TextField
          hiddenLabel
          type="date"
          placeholder="Type here..."
          variant="outlined"
          fullWidth
          id="yearBuilt"
          name="yearBuilt"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.yearBuilt}
          helperText={formik.touched.yearBuilt && formik.errors.yearBuilt}
        />
      </div>
      <div className="form_control">
        <label>Construction materials</label>
        <TextField
          hiddenLabel
          type="text"
          placeholder="Type here..."
          variant="outlined"
          fullWidth
          id="constMate"
          name="constMate"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.constMate}
          helperText={formik.touched.constMate && formik.errors.constMate}
        />
      </div>
      <div className="form_control">
        <label>Flooring</label>
        <TextField
          hiddenLabel
          type="text"
          placeholder="Type here..."
          variant="outlined"
          fullWidth
          id="flooring"
          name="flooring"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.flooring}
          helperText={formik.touched.flooring && formik.errors.flooring}
        />
      </div>
      <div className="form_control">
        <label>Roof</label>
        <TextField
          hiddenLabel
          type="text"
          placeholder="Type here..."
          variant="outlined"
          fullWidth
          id="roof"
          name="roof"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.roof}
          helperText={formik.touched.roof && formik.errors.roof}
        />
      </div>
      <div className="form_control">
        <label>Architectural style</label>
        <TextField
          hiddenLabel
          type="text"
          placeholder="Type here..."
          variant="outlined"
          fullWidth
          id="architectural"
          name="architectural"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.architectural}
          helperText={
            formik.touched.architectural && formik.errors.architectural
          }
        />
      </div>
      <div className="form_btn">
        <SecondaryButton content="Save" onClick={() => setOpen(false)} />
      </div>
    </>
  );
};

export default Construction;
