import Layout from "../../../layout";
import VerificationOtp from "./features/verification";

const Verification = () => {
  return (
    <Layout>
      <main>
        <VerificationOtp />
      </main>
    </Layout>
  );
};

export default Verification;
