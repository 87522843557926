import { Transaction } from "../../../types/General";

type props = {
  transactions: Transaction | undefined;
};
const TotalTransactions = ({ transactions }: props) => {
  return (
    <>
      <div className="transaction_box">
        <div className="gap_m">
          <div className="single">
            <figure>
              <img src="../../images/icCoin.svg" alt="" />
            </figure>
            <div>
              <h3>
                USDT $
                {transactions?.totalEarning
                  ? transactions?.totalEarning.toLocaleString("en-US")
                  : "0"}
              </h3>
              <p>Total Amount Received</p>
            </div>
          </div>
          <div className="single">
            <figure>
              <img src="../../images/icCoin.svg" alt="" />
            </figure>
            <div>
              <h3>
                {" "}
                USDT $
                {transactions?.totalInvestment
                  ? transactions?.totalInvestment.toLocaleString("en-US")
                  : "0"}
              </h3>
              <p>Total Investment</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TotalTransactions;
