const EmbarkComponent = () => {
  return (
    <>
      <div className="left_side">
        <h1>Embark on your real estate investment journey right now.</h1>
        <figure>
          <img src="../../images/city.png" alt="" />
          <img src="../../images/shrubs.png" alt="" />
        </figure>
        {/* <CardMedia
            component='video'
            image={"./../images/auth_video.mp4"}
            autoPlay
            loop
            muted
        /> */}
      </div>
    </>
  );
};

export default EmbarkComponent;
