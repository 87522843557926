import React from "react";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { EmbarkComponent, SecondaryButton } from "../../../../components";
import { useForgotPasswordMutation } from "../../../../service/Auth";
import { useNavigate } from "react-router-dom";
import { Loader, errorToast, successToast } from "../../../../helpers";

const ForgotPasswordContent = () => {
  const navigate = useNavigate();
  const [ForgotPassword, { isLoading }] = useForgotPasswordMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        key: formik.values.email,
      };

      try {
        const response = await ForgotPassword(body).unwrap();
        if (response?.statusCode === 200) {
          const data = {
            email: formik.values.email,
            screenType: "forgot",
          };
          navigate("/verification", { state: data });
          successToast(response?.message);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error);
      }
    },
  });
  return (
    <section className="auth_page">
      <Loader isLoad={isLoading} />
      <div className="side_container">
        <div className="gap_p">
          <EmbarkComponent />
          <div className="right_side">
            <h2>Forgot Your Password ?</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="form_control">
                <TextField
                  className="text_field"
                  hiddenLabel
                  placeholder="Email Address"
                  fullWidth
                  name="email"
                  type="email"
                  variant="outlined"
                  id="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
              <div className="form_btn">
                <SecondaryButton
                  content="Reset Password"
                  // onClick={() => navigate("/reset-password")}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordContent;
