/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LockResetIcon from "@mui/icons-material/LockReset";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "../../helpers";
import { useAppDispatch } from "../../hooks/store";
import { setCredentials } from "../../reducers/authSlice";
import useAuth from "../../hooks/useAuth";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CloseIcon from "@mui/icons-material/Close";
import {
  useCheckWalletAddressMutation,
  useLazyGetProfileQuery,
  usePostLogoutMutation,
} from "../../service/Auth";
import {
  Box,
  Button,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Modal,
  Backdrop,
  Fade,
  Paper,
  Popper,
  Tooltip,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

declare global {
  interface Window {
    ethereum?: any; // This declares the ethereum object on the window
  }
}

const Header = () => {
  const walletRef = useRef(null);
  const location = useLocation();
  const walletAddress = getFromStorage(STORAGE_KEYS.WalletAddress) as any;
  const token = getFromStorage(STORAGE_KEYS.token);

  const userData = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [logoutMethod, logoutMutation] = usePostLogoutMutation();
  const [getProfileMethod] = useLazyGetProfileQuery();
  const [checkUser] = useCheckWalletAddressMutation();

  const [open, setOpen] = useState(false);
  const [userRes, setUserRes] = useState<number | undefined>();
  const [copyAddress, setCopyAddress] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [scroll, setScroll] = useState(false);
  const prevOpen = useRef(open);
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    if (window?.ethereum) {
      window.ethereum.on("chainChanged", (chainId: any) => {
        handleChainChanged(chainId);
      });
    }
  }, []);

  function handleChainChanged(chainId2: any) {
    if (token) {
      handleLogout();
    } else {
      removeFromStorage(STORAGE_KEYS.WalletAddress);
      navigate("/");
    }
  }

  // const handleBlogScroll = () => {
  //   const ele = document.getElementById("blog");
  //   ele?.scrollIntoView({
  //     behavior: "smooth",
  //     block: "nearest",
  //     inline: "start",
  //   });
  // };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const handleLogout = async () => {
    try {
      const res = await logoutMethod({}).unwrap();
      if (res?.statusCode === 200) {
        removeFromStorage(STORAGE_KEYS.token);
        removeFromStorage(STORAGE_KEYS.userData);
        removeFromStorage(STORAGE_KEYS.WalletAddress);
        navigate("/");
        dispatch(
          setCredentials({
            user: null,
            token: null,
          })
        );
      }
    } catch (error: any) {
      // errorToast(error?.data?.message || "");
    }
  };

  const getProfileData = async (token: any) => {
    try {
      const res = await getProfileMethod({}).unwrap();
      if (res?.statusCode === 200) {
        dispatch(
          setCredentials({
            user: res?.data || null,
            token: JSON.parse(`${token}`) || null,
          })
        );
      }
    } catch (error) {
      console.log(error, "eroro");
    }
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const data = getFromStorage(STORAGE_KEYS.userData);

    if (token && data) {
      dispatch(
        setCredentials({
          user: JSON.parse(`${data}`) || null,
          token: JSON.parse(`${token}`) || null,
        })
      );
      getProfileData(token);
    }
  }, []);

  const handleCheckUser = async (account: any) => {
    if (account && !token) {
      const body = {
        walletAddress: account,
      };
      try {
        const response = await checkUser(body).unwrap();
        if (response?.statusCode === 200) {
          setUserRes(response?.statusCode);
        }
      } catch (error: any) {
        setUserRes(400);
      }
    } else {
      // showWarning("Please connect to Metamask");
    }
  };

  const handleloginNavigate = async () => {
    setUserRes(200);
    await navigate("/login");
  };

  const activeClass = () => {
    if (window) {
      setActiveTab(window.location.pathname);
    }
  };
  useEffect(() => {
    activeClass();
  }, [activeTab]);

  const handleCopyWalletAddress = (item: any) => {
    navigator.clipboard.writeText(item);
    setCopyAddress("Copied.");
    setTimeout(() => {
      setCopyAddress("");
    }, 2000);
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", (chainId: any) => {
        handleChainChanged(chainId);
      });
      try {
        window.ethereum.on("accountsChanged", (accounts: any) => {
          if (token) {
            handleLogout();
          } else {
            removeFromStorage(STORAGE_KEYS.WalletAddress);
            navigate("/");
          }
        });

        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        if (accounts.length) {
          setToStorage(STORAGE_KEYS.WalletAddress, accounts[0]);
          await handleCheckUser(accounts[0]);
        }
      } catch (err) {
        console.log(err, "kk");
      }
      try {
        window.ethereum.on("accountsChanged", (accounts: any) => {
          if (token) {
            handleLogout();
          } else {
            removeFromStorage(STORAGE_KEYS.WalletAddress);
            navigate("/");
          }
        });

        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        if (accounts.length) {
          setToStorage(STORAGE_KEYS.WalletAddress, accounts[0]);
          await handleCheckUser(accounts[0]);
        }
      } catch (err) {
        console.log(err, "kk");
      }
    } else {
      window.open("https://metamask.io/download/", "_blank");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      // setChainId(window.ethereum.networkVersion);
      try {
        window.ethereum.on("accountsChanged", (accounts: any) => {
          if (token) {
            handleLogout();
            navigate("/");
          } else {
            removeFromStorage(STORAGE_KEYS.WalletAddress);
            navigate("/");
          }
        });
      } catch (err) {
        console.log(err, "kk");
      }
    }
  }, [userData]);

  useEffect(() => {
    if (walletAddress) {
      handleCheckUser(walletAddress);
    }
  }, [walletAddress]);

  window.onload = (event) => {
    isConnected();
  };

  async function isConnected() {
    const accounts = await window.ethereum.request({ method: "eth_accounts" });
    if (accounts.length) {
    } else {
      if (token) {
        handleLogout();
      } else {
        removeFromStorage(STORAGE_KEYS.WalletAddress);
        navigate("/");
      }
    }
  }

  return (
    <header className={scroll ? "header scrolled" : "header"}>
      <Loader isLoad={logoutMutation?.isLoading} />
      <div className="conta_iner">
        <nav>
          <div className="logo" onClick={() => navigate("/")}>
            <figure>
              <img src="/images/logo.png" alt="" />
            </figure>
          </div>

          <input type="checkbox" id="menu-toggle" />

          <ul className="menu">
            <li className={activeTab === "/" ? "active_cls" : ""}>
              <Link to="/">Home</Link>
            </li>
            <li
              className={activeTab === "/property-market" ? "active_cls" : ""}
            >
              <Link to="/property-market">Properties</Link>
            </li>
            <li className={activeTab === "/blog-listing" ? "active_cls" : ""}>
              <Link to="/blog-listing">Blog</Link>
            </li>
          </ul>
          <ul className="wlr_lgb_tn">
            {walletAddress ? (
              <Tooltip title={copyAddress} ref={walletRef}>
                <li
                  className="btn btn_primary br"
                  onClick={() => handleCopyWalletAddress(walletAddress)}
                >
                  <figure>
                    <img src="/images/meta.svg" alt="meta" />
                  </figure>

                  {walletAddress?.length > 13
                    ? walletAddress?.slice(0, 6) +
                    "..." +
                    walletAddress?.slice(-5)
                    : "abcd"}
                </li>
              </Tooltip>
            ) : (
              <li className="btn btn_primary br" onClick={connectWallet}>
                <figure>
                  <img src="/images/meta.svg" alt="meta" />
                </figure>
                connect wallet
              </li>
            )}

            {userData ? (
              <li>
                <Button
                  className="btn btn_secondary"
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? "composition-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <figure>
                    <img src="/images/user.svg" alt="meta" />
                  </figure>
                  {userData?.firstName || "User"}
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="top-end"
                  transition
                  disablePortal
                  className="account_dropdown"
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "top-end"
                            ? "right bottom"
                            : "right top",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem onClick={() => navigate("/transactions")}>
                              <figure>
                                <img
                                  src="/images/icon_transaction.svg"
                                  alt="meta"
                                />
                              </figure>
                              Transactions
                            </MenuItem>
                            {/* <MenuItem>
                                <figure>
                                  <img
                                    src="./images/icon_apartment.svg"
                                    alt="meta"
                                  />
                                </figure>
                                My Listed Property Token
                              </MenuItem> */}
                            <MenuItem
                              onClick={() => navigate("/my-properties")}
                            >
                              <figure>
                                <img
                                  src="/images/icon_apartment.svg"
                                  alt="meta"
                                />
                              </figure>
                              My Properties
                            </MenuItem>
                            {userData?.userStatus === "APPROVED" ? (
                              <MenuItem
                                onClick={() => navigate("/add-property")}
                              >
                                <figure>
                                  <img
                                    src="/images/icon_real-estate-agent.svg"
                                    alt="meta"
                                  />
                                </figure>
                                List Your Property
                              </MenuItem>
                            ) : null}

                            <MenuItem
                              onClick={() => navigate("/change-password")}
                            >
                              <IconButton className="drpdwn_icns">
                                <LockResetIcon />
                              </IconButton>
                              Change Password
                            </MenuItem>
                            <MenuItem onClick={() => navigate("/view-profile")}>
                              <figure>
                                <img
                                  src="/images/icon_profile.svg"
                                  alt="meta"
                                />
                              </figure>
                              Profile
                            </MenuItem>
                            <MenuItem onClick={() => setOpenModal(true)}>
                              <figure>
                                <img src="/images/icon_logout.svg" alt="meta" />
                              </figure>
                              Logout
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </li>
            ) : (
              <>
                {walletAddress ? (
                  <li>
                    {userRes === 200 ? (
                      <button
                        type="button"
                        className="btn btn_secondary"
                        onClick={handleloginNavigate}
                      >
                        <figure>
                          <img src="/images/user.svg" alt="meta" />
                        </figure>
                        login
                      </button>
                    ) : userRes === 400 ? (
                      <button
                        type="button"
                        className="btn btn_secondary"
                        onClick={() => {
                          setUserRes(400);
                          navigate("/create-account");
                        }}
                      >
                        <figure>
                          <img src="/images/user.svg" alt="meta" />
                        </figure>
                        Sign Up
                      </button>
                    ) : null}
                  </li>
                ) : null}
              </>
            )}
          </ul>

          <label htmlFor="menu-toggle" className="menu-icon"><MenuIcon /></label>
        </nav>
      </div>

      <Modal
        className="modal confirmation_modal"
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box className="modalBox_body">
            <Box>
              <Button className="close_btn" onClick={() => setOpenModal(false)}>
                <CloseIcon />
              </Button>
              <h2>You are attempting to log out of Real Estate.</h2>
              <p>Are you sure you want to logout ?</p>
              <div className="flexdiv">
                <Button
                  className="cancel_btn"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn btn_secondary"
                  onClick={() => {
                    setOpenModal(false);
                    handleLogout();
                  }}
                >
                  Log Out
                </Button>
              </div>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </header>
  );
};

export default Header;
