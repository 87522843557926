import React from "react";
import "../../auth/auth.scss";
import Layout from "../../../layout";
import ProfileContent from "./features/profile";

const Profile = () => {
  return (
    <Layout>
      <main>
        <ProfileContent />
      </main>
    </Layout>
  );
};

export default Profile;
