/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useLazyGetFaqListQuery } from "../../../service/Common";
import { FaqData } from "../../../types/General";

export default function HomeFaq() {
  const [getFaqMethod] = useLazyGetFaqListQuery();

  const [faqs, setFaqs] = useState<FaqData[]>([]);

  const getFaqListing = async () => {
    try {
      const res = await getFaqMethod({}).unwrap();
      if (res?.statusCode === 200) {
        setFaqs(res?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFaqListing();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="faq_sc ub_spc">
      <div className="conta_iner">
        <div className="text_center hd_1 mb_60">
          <h2>Questions? Look here.</h2>
        </div>
        {faqs?.length ? (
          faqs?.map((item) => (
            <Accordion key={item?.id} className="accordion_item">
              <AccordionSummary
                className="accordion_title"
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                {item?.question || ""}
              </AccordionSummary>
              <AccordionDetails className="accordion_content">
                <p>{item?.answer || ""}</p>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <p>No faq's listing found</p>
        )}
      </div>
    </section>
  );
}
