import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import { TransactionRes } from "../../../types/General";
import moment from "moment";

type props = {
  transactionsHistory: TransactionRes[];
};

const TransactionsHistory = ({ transactionsHistory }: props) => {
  return (
    <div className="transaction_table">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Tokens</TableCell>
              <TableCell>Property</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsHistory?.length ? (
              transactionsHistory?.map((row) => (
                <TableRow key={row?._id}>
                  <TableCell>
                    {moment(row?.createdAt).format("MMM Do YY")}
                  </TableCell>
                  <TableCell align="center">
                    {row?.boughtTokens || "0"}
                  </TableCell>

                  <TableCell style={{ textTransform: "capitalize" }}>
                    {row?.propertyTitle}
                  </TableCell>
                  <TableCell
                    style={{ color: row?.tokenExpense ? "red" : "green" }}
                  >
                    USDT $
                    {row?.tokenExpense
                      ? row?.tokenExpense.toLocaleString("en-US")
                      : row?.profitRecieved.toLocaleString("en-US")}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={4}
                  style={{ textAlign: "center" }}
                >
                  No Transaction Yet!
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TransactionsHistory;
