/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropertyGrid from "../../../components/propertyGrid";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { GetPropertyDetail } from "../../../types/General";
import { useGetAllPropertyMutation } from "../../../service/Property";
import { Loader } from "../../../helpers";
import FilterDrawer from "./filterDrawer";
import { useLazyGetAllListedCountryQuery } from "../../../service/Common";

const PropertyMarketListing = () => {
  const navigate = useNavigate();
  const [getPropertyByName, { isLoading }] = useGetAllPropertyMutation();
  const [getAllListedCountryMuation, getAllListedCountryData] =
    useLazyGetAllListedCountryQuery();

  const [isDrawerActive, setDrawerIsActive] = useState(false);
  const [getAllCountry, setGetAllCountry] = useState<string[]>([]);
  const [getProperty, setGetProperty] = useState<GetPropertyDetail[]>([]);
  console.log("getProperty: ", getProperty);

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [sliderValue, setSliderValue] = useState<string | number>("");
  const [sliderValueProperty, setSliderValueProperty] = useState<string[]>([]);
  const [favItems, setFavItems] = useState<GetPropertyDetail[]>([]);
  const [sliderValueBathroom, setSliderValueBathroom] = useState<
    string | number
  >("");

  const getAllPropertiesData = async () => {
    try {
      const res = await getPropertyByName({
        search: "",
        bedroom: sliderValue,
        bathrooms: sliderValueBathroom,
        location: selectedValues,
        propertyType: sliderValueProperty,
      }).unwrap();

      if (res?.statusCode === 200) {
        setGetProperty(res?.data?.property || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllListedCountry = async () => {
    try {
      const res = await getAllListedCountryMuation({}).unwrap();

      if (res?.statusCode === 200) {
        setGetAllCountry(res?.data?.uniqueCountries);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkFavProperties = () => {
    const fav_data = getProperty
      .filter((item: GetPropertyDetail) => item?.isFavourite)
      .map((item: any) => item);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProperties();
  }, [getProperty]);

  useEffect(() => {
    getAllPropertiesData();
    getAllListedCountry();
  }, [selectedValues, sliderValueBathroom, sliderValue, sliderValueProperty]);

  // Define the type of uniqueObjects explicitly
  let uniqueObjects: { [key: string]: (typeof getProperty)[0] } = {};

  for (let obj of getProperty) {
    // Create a unique key based on "name" and "img"
    let key = obj.country;

    // If the key doesn't exist in uniqueObjects, add the object
    if (!uniqueObjects[key]) {
      uniqueObjects[key] = obj;
    }
  }

  // Extract the values (unique objects) from the uniqueObjects object
  let uniqueArr = Object.values(uniqueObjects);

  return (
    <>
      <Loader isLoad={isLoading || getAllListedCountryData?.isLoading} />
      <section className="propertyListing_sc prprty_mrkt">
        <div className="conta_iner">
          {/* <div className="head">
            <h1>Property Market</h1>
            <button
              className="btn btn_primary br"
              onClick={() => setDrawerIsActive(!isDrawerActive)}
            >
              <img src="/images/icon_filter.svg" alt="" /> Filterss
            </button>
          </div> */}
          <div className="gap_p">
            <div className="left_side v2_grid">
              <Swiper
                className="location_swiper"
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={30}
                slidesPerView={3}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                pagination={{
                  el: ".swiper-pagination",
                  dynamicBullets: true,
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                loop
                breakpoints={{
                  300: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                  600: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  992: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
              >
                <div className="inner_head">
                  <h1>Property Market</h1>
                  <div className="swiper_action">
                    <div className="swiper-button-prev">
                      <ArrowBackIcon />
                    </div>
                    <div className="swiper-pagination"></div>
                    <div className="swiper-button-next">
                      <ArrowForwardIcon />
                    </div>
                  </div>

                  <div className="filter_btn">
                    <button
                      className="btn btn_primary br"
                      onClick={() => setDrawerIsActive(!isDrawerActive)}
                    >
                      <img src="/images/icon_filter.svg" alt="" /> Filter
                    </button>
                  </div>
                </div>
                {uniqueArr &&
                  uniqueArr?.map((item) => (
                    <SwiperSlide key={item?._id}>
                      <div
                        className="single_slide"
                        onClick={() =>
                          navigate("/property-location", {
                            state: item?.country,
                          })
                        }
                      >
                        <figure>
                          <img
                            src={
                              item?.propertyImages
                                ? item?.propertyImages[0]
                                : ""
                            }
                            alt=""
                          />
                        </figure>
                        <h3>{item?.country || ""}</h3>
                        {/* <p>{item?.propertyCount || "0"} Properties</p> */}
                        {/* {item?.cityPropertyCounts &&
                          item?.cityPropertyCounts?.map((item) => (
                            <>
                              <h3>{item?._id || ""}</h3>
                              <p>{item?.propertyCount || "0"} Properties</p>
                            </>
                          ))} */}
                      </div>
                    </SwiperSlide>
                  ))}
                {/* <div className="v2_grid">
                  <PropertyGrid />
                </div> */}
              </Swiper>
              <PropertyGrid
                propertyDetail={getProperty}
                favItems={favItems}
                setFavItems={setFavItems}
              />
            </div>

            <div className="right_side">
              <div className="filter_btn">
                <button
                  className="btn btn_primary br"
                  onClick={() => setDrawerIsActive(!isDrawerActive)}
                >
                  <img src="/images/icon_filter.svg" alt="" /> Filter
                </button>
              </div>
              <div className="map">
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3317.36476008907!2d-84.39939902366092!3d33.751238933563066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f503789ca5cb03%3A0x7cf5c19acca6916f!2s125%20Ted%20Turner%20Dr%20SW%2C%20Atlanta%2C%20GA%2030313%2C%20USA!5e0!3m2!1sen!2sin!4v1694434132228!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FilterDrawer
        propertyDetail={getProperty}
        isDrawerActive={isDrawerActive}
        setDrawerIsActive={setDrawerIsActive}
        setSelectedValues={setSelectedValues}
        selectedValues={selectedValues}
        setSliderValue={setSliderValue}
        sliderValue={sliderValue}
        setSliderValueBathroom={setSliderValueBathroom}
        sliderValueBathroom={sliderValueBathroom}
        setSliderValueProperty={setSliderValueProperty}
        sliderValueProperty={sliderValueProperty}
        getAllCountry={getAllCountry}
      />

      {/* {
  arr?.length ? Array.from(new Set(arr?.map(item) => item?.name)).map((uniqueval) => {
    return(
      console.log(uniqueval, 'val');
      
    )
  }): null
} */}

      {/* {arr?.length
        ? Array.from(new Set(arr?.map((item) => item?.name))).map(
            (uniqueVal) => {
              console.log(uniqueVal, "val");
              return null;
            }
          )
        : null} */}
    </>
  );
};

export default PropertyMarketListing;
