import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { ProfitHistoryBody } from "../../types/General";

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  formik: any;
  profitDetail: ProfitHistoryBody[];
  setProfitDetail: Dispatch<SetStateAction<ProfitHistoryBody[]>>;
};

const ProfitHistory = ({
  setOpen,
  formik,
  profitDetail,

  setProfitDetail,
}: Props) => {
  const [addNew, setAddNew] = useState<boolean>(true);

  const handleSave = () => {
    if (
      formik.values.profitDate === "" ||
      formik.values.profitPrice === "" ||
      formik.values.profitAddr === ""
    ) {
      return;
    }
    setProfitDetail((prevDetail) => [
      ...prevDetail,
      {
        Date: formik.values.profitDate,
        Profit: formik.values.profitPrice,
        address: formik.values.profitAddr,
      },
    ]);
    formik.setFieldValue("profitDate", "");
    formik.setFieldValue("profitPrice", "");
    formik.setFieldValue("profitAddr", "");
    setAddNew(false);
  };

  return (
    <>
      <div className="flex_head">
        <h2>Profit History</h2>
        <Button className="close_btn" onClick={() => setOpen(false)}>
          <CloseIcon />
        </Button>
      </div>
      {profitDetail?.map((item, i) => {
        return (
          <div className="profit_detail" key={i}>
            <p>
              Profit : <strong>{item?.Profit}</strong>
            </p>
            <p>
              Date : <strong>{item?.Date}</strong>
            </p>
            <p>
              Address : <strong>{item?.address}</strong>
            </p>
          </div>
        );
      })}
      <div className="flex_label">
        <label>Profit History</label>
        <span className="add" onClick={() => setAddNew(true)}>
          Add New <AddIcon />
        </span>
      </div>
      {addNew ? (
        <>
          <div className="form_control">
            <div className="gap_p">
              <div>
                <label>Date</label>
                <TextField
                  className="text_field"
                  hiddenLabel
                  placeholder="Date of profit distribution"
                  fullWidth
                  name="profitDate"
                  variant="outlined"
                  type={"date"}
                  id="profitDate"
                  value={formik.values.profitDate || null}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.profitDate && formik.errors.profitDate
                  }
                />
              </div>
              <div>
                <label>Profit</label>
                <TextField
                  hiddenLabel
                  type="text"
                  placeholder="Profit"
                  variant="outlined"
                  fullWidth
                  name="profitPrice"
                  id="profitPrice"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.profitPrice}
                  helperText={
                    formik.touched.profitPrice && formik.errors.profitPrice
                  }
                />
              </div>
              <div>
                <label>Address</label>
                <TextField
                  hiddenLabel
                  type="text"
                  placeholder="Address"
                  variant="outlined"
                  fullWidth
                  name="profitAddr"
                  id="profitAddr"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.profitAddr}
                  helperText={
                    formik.touched.profitAddr && formik.errors.profitAddr
                  }
                />
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className="form_btn">
        <button className="btn btn_secondary" onClick={handleSave}>
          save
        </button>
      </div>
    </>
  );
};

export default ProfitHistory;
