import React, { useState } from "react";
import "../../auth/auth.scss";
import Layout from "../../../layout";
import { EmbarkComponent } from "../../../components";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import { Backdrop, Box, Button, Fade, Input, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { UploadMedia, UploadVideo } from "../../../utils/mediaUpload";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Loader, errorToast } from "../../../helpers";
import { useUpdateProfitPropertyMutation } from "../../../service/Property";
import { useNavigate, useParams } from "react-router-dom";

const UploadPictureAndVideo = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [updateProfitSharing, { isLoading }] =
    useUpdateProfitPropertyMutation();

  const [myImages, setMyImages] = useState<any>([]);
  const [myVideos, setMyVideos] = useState<any>([]);
  const [open_successModal, setOpen_successModal] = React.useState(false);
  const handleClose_successModal = () => setOpen_successModal(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const uploadedImages = Array.from(files).map((file) => ({
        file,
        url: URL.createObjectURL(file),
      }));
      setMyImages([...myImages, ...uploadedImages]);
    }
  };

  const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const uploadedVideo = Array.from(files).map((file) => ({
        file,
        url: URL.createObjectURL(file),
      }));
      setMyVideos([...myVideos, ...uploadedVideo]);
    }
  };

  const handleCrossClick = async (e: any, i: any) => {
    e.stopPropagation();
    if (myImages.includes(i)) {
      let arr = [];
      arr = myImages.filter((name: any) => name !== i);
      setMyImages(arr);
    }
  };

  const handleCrossVideoClick = async (e: any, i: any) => {
    e.stopPropagation();
    if (myVideos.includes(i)) {
      let arr = [];
      arr = myVideos.filter((name: any) => name !== i);
      setMyVideos(arr);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      picDes: "",
      VdoDes: "",
    },
    validationSchema: Yup.object({
      picDes: Yup.string().required("Email is required!"),
      VdoDes: Yup.string().required("Password is required!"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      setLoading(true);
      let images: string[] = [];
      let videos: string[] = [];
      if (myImages?.length) {
        setLoading(true);

        await Promise.all(
          myImages?.map(async (item: any) => {
            const res = await UploadMedia(item?.file);
            if (res?.statusCode === 200 && res?.data?.length) {
              console.log(res, "image");
              images?.push(res?.data);
            }
          })
        );
      }
      if (myVideos?.length) {
        setLoading(true);

        await Promise.all(
          myVideos?.map(async (item: any) => {
            const res = await UploadVideo(item?.file);
            if (res?.statusCode === 200 && res?.data?.length) {
              console.log(res, "videooo");
              videos?.push(res?.data);
            }
          })
        );
      }
      setLoading(false);
      if (images?.length < 3 || !videos?.length) {
        setError(true);
        return;
      }
      setError(false);

      const body = {
        imageDescription: formik.values.picDes,
        videoDescription: formik.values.VdoDes,
        propertyImages: images,
        propertyVideos: videos,
      };
      try {
        const response = await updateProfitSharing({
          property_id: id,
          body,
        }).unwrap();
        if (response?.statusCode === 200) {
          setOpen_successModal(true);
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  return (
    <Layout>
      <Loader isLoad={isLoading || loading} />
      <main>
        <section className="auth_page">
          <div className="side_container">
            <div className="gap_p">
              <EmbarkComponent />
              <div className="right_side">
                <h2>Upload Picture and Video</h2>
                <form onSubmit={formik.handleSubmit}>
                  <div className="form_control">
                    <h5>Pictures</h5>
                    <label>Description</label>
                    <div className="gap_p">
                      <div>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          multiline
                          rows={5}
                          type="text"
                          placeholder="Description"
                          fullWidth
                          variant="outlined"
                          name="picDes"
                          id="picDes"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.picDes}
                          helperText={
                            formik.touched.picDes && formik.errors.picDes
                          }
                        />
                      </div>

                      {myImages?.length ? (
                        <>
                          {myImages?.map((item: any, i: any) => {
                            return (
                              <div className="upload_document w_50" key={i}>
                                <div className="inner">
                                  <div className="cross_icn">
                                    <CloseIcon
                                      onClick={(e) => handleCrossClick(e, item)}
                                    />
                                  </div>

                                  <figure>
                                    <img src={item?.url} alt="upload" />
                                  </figure>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : null}

                      <div className="upload_document v2 w_50">
                        <label htmlFor="icon-button-file">
                          <div className="inner">
                            <AddIcon />
                            <Input
                              id="icon-button-file"
                              type="file"
                              inputProps={{
                                accept: "image/png,image/jpeg",
                              }}
                              onChange={handleFileUpload}
                            />
                          </div>
                        </label>
                      </div>

                      {error && !myImages?.length ? (
                        <h6 className="err_msg">This field is compulsory.</h6>
                      ) : (
                        ""
                      )}
                      {error && myImages?.length < 3 && myImages?.length ? (
                        <h6 className="err_msg">
                          Minimum 3 images are required.
                        </h6>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="form_control">
                    <h5>Videos</h5>
                    <label>Description</label>
                    <div className="gap_p">
                      <div>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          multiline
                          rows={5}
                          type="text"
                          placeholder="Description"
                          fullWidth
                          variant="outlined"
                          name="VdoDes"
                          id="VdoDes"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.VdoDes}
                          helperText={
                            formik.touched.VdoDes && formik.errors.VdoDes
                          }
                        />
                      </div>

                      {myVideos?.length ? (
                        <>
                          {myVideos?.map((item: any, i: any) => {
                            return (
                              <div className="upload_document v2 w_50" key={i}>
                                <div className="inner">
                                  <div
                                    className="cross_icn"
                                    style={{ zIndex: 1 }}
                                  >
                                    <CloseIcon
                                      style={{ fontSize: 20 }}
                                      onClick={(e) =>
                                        handleCrossVideoClick(e, item)
                                      }
                                    />
                                  </div>
                                  <video controls width="100%" height="100%">
                                    <source src={item?.url} type="video/mp4" />
                                  </video>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : null}

                      <div className="upload_document w_50">
                        <label htmlFor="icon-button-file-vdo">
                          <div className="inner">
                            <AddIcon />
                            <Input
                              id="icon-button-file-vdo"
                              type="file"
                              inputProps={{
                                accept: "video/*",
                              }}
                              onChange={handleVideoUpload}
                            />
                          </div>
                        </label>
                      </div>

                      {error && !myVideos?.length ? (
                        <h6 className="err_msg">This field is compulsory.</h6>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="gap_p">
                      {/* <div className="upload_document w_50">
                        {frontImg ? (
                          <div className="inner">
                            <div className="cross_icn">
                              <CloseIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setFrontImg("");
                                }}
                              />
                            </div>

                            <figure>
                              <img src={frontImg} alt="" />
                            </figure>
                          </div>
                        ) : (
                          <label htmlFor="icon-button-file">
                            <div className="inner">
                              <AddIcon />
                              <Input
                                id="icon-button-file"
                                type="file"
                                inputProps={{
                                  accept: "image/png,image/jpeg",
                                }}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => handleImageUpload(e, "front")}
                              />
                            </div>

                            {error && !frontImg ? (
                              <h6 className="err_msg">
                                This field is required
                              </h6>
                            ) : null}
                          </label>
                        )}
                      </div> */}
                      {/* <div className="upload_document w_50">
                        {backImg ? (
                          <div className="inner">
                            <div className="cross_icn">
                              <CloseIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setBackImg("");
                                }}
                              />
                            </div>

                            <figure>
                              <img src={backImg} alt="" />
                            </figure>
                          </div>
                        ) : (
                          <label htmlFor="icon-button-file2">
                            <div className="inner">
                              <AddIcon />
                              <Input
                                id="icon-button-file2"
                                type="file"
                                inputProps={{
                                  accept: "image/png,image/jpeg",
                                }}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => handleImageUpload(e, "back")}
                              />
                            </div>

                            {error && !backImg ? (
                              <h6 className="err_msg">
                                This field is required
                              </h6>
                            ) : null}
                          </label>
                        )}
                      </div> */}
                    </div>
                  </div>
                  <div className="form_btn">
                    <button
                      className="btn btn_secondary"
                      onClick={() => setError(true)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* Success Modal */}
      <Modal
        className="modal success_modal"
        open={open_successModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open_successModal}>
          <Box className="modalBox_body">
            <Box>
              <Button className="close_btn" onClick={handleClose_successModal}>
                <CloseIcon />
              </Button>
              <figure>
                <img src="../../images/success-tick.svg" alt="" />
              </figure>
              <h2>Awesome !</h2>
              <p>
                "Admin will approve your property request in 2 to 3 business
                days.
              </p>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Layout>
  );
};

export default UploadPictureAndVideo;
