/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropertyGrid from "../../../components/propertyGrid";

// import { GoogleMap } from "@react-google-maps/api";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useLazyGetAllPropertyByCityQuery } from "../../../service/Property";
import { GetPropertyDetail } from "../../../types/General";
import { useLocation } from "react-router-dom";
import { Loader } from "../../../helpers";

const PropertyLocationListing = () => {
  const location = useLocation();
  const { state } = location;

  const [favItems, setFavItems] = useState<GetPropertyDetail[]>([]);
  const [getPropertyByCityName, { isLoading }] =
    useLazyGetAllPropertyByCityQuery();

  const [getProperty, setGetProperty] = useState<GetPropertyDetail[]>([]);

  const getPropertyByCity = async () => {
    try {
      const res = await getPropertyByCityName({
        property_city: state,
      }).unwrap();

      if (res?.statusCode === 200) {
        setGetProperty(res?.data?.property || []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const checkFavProperties = () => {
    const fav_data = getProperty
      .filter((item: GetPropertyDetail) => item?.isFavourite)
      .map((item: any) => item);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProperties();
  }, [getProperty]);

  useEffect(() => {
    getPropertyByCity();
  }, []);

  return (
    <>
      <Loader isLoad={isLoading} />
      <section className="propertyListing_sc">
        <div className="conta_iner">
          <div className="head">
            <h1>{state || ""}</h1>
            {/* <button
              className="btn btn_primary br"
              onClick={() => setDrawerIsActive(!isDrawerActive)}
            >
              <img src="../../images/icon_filter.svg" alt="" /> Filter
            </button> */}
          </div>
          <div className="gap_p">
            <div className="left_side">
              <div className="location_swiper">
                <div className="inner_head">
                  <h1>{state || ""}</h1>
                  {/* <div className="swiper_action">
                    <div className="swiper-button-prev">
                      <ArrowBackIcon />
                    </div>
                    <div className="swiper-button-next">
                      <ArrowForwardIcon />
                    </div>
                  </div> */}
                </div>
                <div className="v2_grid">
                  <PropertyGrid
                    propertyDetail={getProperty}
                    favItems={favItems}
                    setFavItems={setFavItems}
                  />
                </div>
              </div>
            </div>
            <div className="right_side">
              <div className="filter_btn">
                {/* <button
                  className="btn btn_primary br"
                  onClick={() => setDrawerIsActive(!isDrawerActive)}
                >
                  <img src="/images/icon_filter.svg" alt="" /> Filter
                </button> */}
              </div>
              <div className="map">
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                {/* <GoogleMap
                  // className="googlemap"
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={10}
                  // defaultZoom={8}
                  // onUnmount={onUnmount}
                >
                  <Marker position={{ lat: lat, lng: long }}>
          <figure>
            <img src="/static/images/marker.png" />
          </figure>
        </Marker>
                  <></>
                </GoogleMap> */}
                <iframe
                  title="location"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3317.36476008907!2d-84.39939902366092!3d33.751238933563066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f503789ca5cb03%3A0x7cf5c19acca6916f!2s125%20Ted%20Turner%20Dr%20SW%2C%20Atlanta%2C%20GA%2030313%2C%20USA!5e0!3m2!1sen!2sin!4v1694434132228!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PropertyLocationListing;
