import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import SecondaryButton from "../../../../components/button/secondaryButton";
import { IconButton, Input, InputAdornment } from "@mui/material";
import { UploadMedia } from "../../../../utils/mediaUpload";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
} from "../../../../helpers";
import * as Yup from "yup";
import { useFormik } from "formik";
import { EmbarkComponent } from "../../../../components";
import { isNumber, isString } from "../../../../utils/validation";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { usePostSignupMutation } from "../../../../service/Auth";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const CreateAccountContent = () => {
  const walletAddress = getFromStorage(STORAGE_KEYS.WalletAddress);
  const navigate = useNavigate();

  const [signUpMethod, signUpMutation] = usePostSignupMutation();

  const [image, setImage] = useState<string>("");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      street: "",
      phone: "",
      location: "",
      zipCode: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("First name is required")
        .min(2, "Minimum 2 characters are required"),
      lastName: Yup.string()
        .required("Last name is required")
        .min(2, "Minimum 2 characters are required"),
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
      street: Yup.string()
        .required("Street is required")
        .min(2, "Minimum 2 characters are required"),
      location: Yup.string()
        .required("Location is required")
        .min(2, "Minimum 2 characters are required"),
      zipCode: Yup.string()
        .required("Zipcode is required")
        .min(2, "Minimum 2 characters are required"),
      password: Yup.string()
        .required("Password is required!")
        .matches(
          /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character. "
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async () => {
      setError(false);
      if (!image) {
        setError(true);
        return;
      }
      formik.setSubmitting(true);
      let body = {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        phone: formik.values.phone,
        countryCode: phoneCode,
        key: formik.values.email,
        address1: formik.values.street,
        userLocation: {
          lat: -34.928567,
          lng: -56.168106,
        },
        locationKey: formik.values.location,
        zipCode: formik.values.zipCode,
        password: formik.values.password,
        profilePic: image,
        walletAddress: walletAddress,
      };
      // navigate("/upload-document", { state: body });

      try {
        const response = await signUpMethod(body).unwrap();
        if (response?.statusCode === 200) {
          navigate("/upload-document", {
            state: {
              email: response?.data?.email,
              id: response?.data?._id,
            },
          });
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl = document.getElementById("password");
    if (inputEl) {
      setTimeout(() => {
        if (inputEl instanceof HTMLInputElement) {
          inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
        }
      }, 0);
    }
  };
  const handleshowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
    const inputEl = document.getElementById("confirmPassword");
    if (inputEl) {
      setTimeout(() => {
        if (inputEl instanceof HTMLInputElement) {
          inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
        }
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    try {
      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        setImage(res?.data);
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };

  return (
    <section className="auth_page">
      <Loader isLoad={signUpMutation?.isLoading} />
      <div className="side_container">
        <div className="gap_p">
          <EmbarkComponent />
          <div className="right_side">
            <h2>Create your account</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="form_control">
                {image ? (
                  <div className="upload_image">
                    <div className="cross_icn2">
                      <CloseIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          setImage("");
                        }}
                      />
                    </div>
                    <figure>
                      <img
                        src={image ? image : "/images/user_placeholder.svg"}
                        alt=""
                      />
                    </figure>
                  </div>
                ) : (
                  <label htmlFor="icon-button-file" className="upload_image">
                    <>
                      <span>
                        <img src="/images/user_placeholder.svg" alt="" />
                      </span>
                      <Input
                        //   sx={{ display: "none" }}
                        id="icon-button-file"
                        type="file"
                        inputProps={{
                          accept: "image/png,image/jpeg",
                        }}
                        onChange={handleImageUpload}
                      />
                    </>
                  </label>
                )}
                {error && !image ? (
                  <h6 className="err_msg">This field is required</h6>
                ) : (
                  ""
                )}
              </div>

              <div className="gap_p">
                <div className="form_control w_50">
                  <TextField
                    hiddenLabel
                    fullWidth
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    variant="outlined"
                    id="firstName"
                    className="text_field"
                    onChange={(val) => {
                      if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    inputProps={{ maxLength: 20 }}
                  />
                </div>
                <div className="form_control w_50">
                  <TextField
                    hiddenLabel
                    fullWidth
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    variant="outlined"
                    id="lastName"
                    className="text_field"
                    onChange={(val) => {
                      if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    inputProps={{ maxLength: 20 }}
                  />
                </div>
              </div>
              <div className="form_control">
                <TextField
                  hiddenLabel
                  fullWidth
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  variant="outlined"
                  id="email"
                  className="text_field"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
              <div className="form_control">
                <PhoneInput
                  enableSearch={true}
                  value={phoneCode + formik.values.phone}
                  country={"us"}
                  inputClass="phoneInput"
                  dropdownClass="phone_drpdwn"
                  // inputStyle={{
                  //   color: "white",
                  //   width: "100%",
                  //   background: "#373737",
                  //   border: "1px solid transparent",
                  // }}
                  buttonClass="phoneBtn"
                  placeholder="Phone Number"
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <h6 className="err_msg">
                    {formik.touched.phone && formik.errors.phone}
                  </h6>
                ) : (
                  ""
                )}
              </div>
              <h3>Address</h3>
              <div className="form_control">
                <TextField
                  hiddenLabel
                  fullWidth
                  type="text"
                  placeholder="Street Name"
                  name="street"
                  variant="outlined"
                  id="street"
                  className="text_field"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.street}
                  helperText={formik.touched.street && formik.errors.street}
                  inputProps={{ maxLength: 30 }}
                />
              </div>
              <div className="form_control">
                <TextField
                  hiddenLabel
                  fullWidth
                  type="text"
                  placeholder="Location (Google Places API)"
                  name="location"
                  variant="outlined"
                  id="location"
                  className="text_field"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.location}
                  helperText={formik.touched.location && formik.errors.location}
                />
              </div>
              <div className="form_control">
                <TextField
                  hiddenLabel
                  fullWidth
                  type="text"
                  placeholder="Pincode/Zipcode"
                  name="zipCode"
                  id="zipCode"
                  className="text_field"
                  onChange={(val) => {
                    if (isNumber(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.zipCode}
                  helperText={formik.touched.zipCode && formik.errors.zipCode}
                  inputProps={{ maxLength: 10 }}
                />
              </div>
              <h3>Password</h3>
              <div className="form_control">
                <TextField
                  sx={{ m: 0 }}
                  className="text_field"
                  hiddenLabel
                  placeholder="Password"
                  fullWidth
                  margin="normal"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className="eye_btn" position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form_control">
                <TextField
                  sx={{ m: 0 }}
                  className="text_field"
                  hiddenLabel
                  placeholder="Confirm Password"
                  fullWidth
                  margin="normal"
                  id="confirmPassword"
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  type={showConfirmPassword ? "text" : "password"}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className="eye_btn" position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleshowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form_btn">
                <SecondaryButton
                  content="Next"
                  onClick={() => setError(true)}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateAccountContent;
