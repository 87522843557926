/* eslint-disable react-hooks/exhaustive-deps */
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { useNavigate } from "react-router-dom";
import { STORAGE_KEYS, getFromStorage } from "../../../helpers";
import { useCheckWalletAddressMutation } from "../../../service/Auth";
import useAuth from "../../../hooks/useAuth";

const HomeBecomeSeller = () => {
  const userData = useAuth();
  var walletAddress = "";
  const navigate = useNavigate();
  const token = getFromStorage(STORAGE_KEYS.token);

  // console.log("handleJoinUs: ", walletAddress);
  const [checkUser] = useCheckWalletAddressMutation();

  // const handleJoinUs = () => {
  //   if (token) {
  //     navigate("/add-property");
  //   } else if (walletAddress && token === null) {
  //     console.log("hand");

  //     handleCheckUser();
  //   } else {
  //     navigate("/");
  //   }
  // };

  const handleCheckUser = async () => {
    walletAddress = getFromStorage(STORAGE_KEYS.WalletAddress) as any;
    if (walletAddress && !token) {
      const body = {
        walletAddress: walletAddress,
      };
      try {
        const response = await checkUser(body).unwrap();
        if (response?.statusCode === 200) {
          // setUserRes(response?.statusCode);
          navigate("/login");
        }
      } catch (error: any) {
        console.log("rspns", error);
        // setUserRes(400);
        navigate("/create-account");
      }
    } else if (token && userData?.userStatus === "APPROVED") {
      navigate("/add-property");
    } else {
      navigate("/");
    }
  };

  return (
    <section className="cta_sc">
      <div className="conta_iner">
        <div className="inner">
          <div className="hd_1">
            <h2>Become a Seller</h2>
            <p>Tokenize and sell your property in few simple steps </p>
          </div>
          <span onClick={handleCheckUser} className="btn_rounded">
            <NorthEastIcon /> Join Us
          </span>
        </div>
      </div>
    </section>
  );
};

export default HomeBecomeSeller;
