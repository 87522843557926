import React from "react";
import "../../auth/auth.scss";
import Layout from "../../../layout";
import { EmbarkComponent, SecondaryButton } from "../../../components";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import { Input } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

const OwnerTransferUploadPictureAndVideo = () => {
  const [open_successModal, setOpen_successModal] = React.useState(false);
  const handleOpen_successModal = () => setOpen_successModal(true);
  const handleClose_successModal = () => setOpen_successModal(false);

  return (
    <Layout>
      <main>
        <section className="auth_page">
          <div className="side_container">
            <div className="gap_p">
              <EmbarkComponent />
              <div className="right_side">
                <h2>Upload Picture and Video</h2>
                {/* <form action=""> */}
                <div className="form_control">
                  <h5>Pictures</h5>
                  <label>Description</label>
                  <div className="gap_p">
                    <div>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        multiline
                        rows={5}
                        type="text"
                        placeholder="Description"
                        fullWidth
                        variant="outlined"
                      />
                    </div>
                    <div className="upload_document v2 w_50">
                      <label htmlFor="icon-picture1">
                        <div className="inner">
                          <AddIcon />
                          <Input
                            id="icon-picture1"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                          />
                        </div>
                      </label>
                    </div>
                    <div className="upload_document v2 w_50">
                      <label htmlFor="icon-picture2">
                        <div className="inner">
                          <AddIcon />
                          <Input
                            id="icon-picture2"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form_control">
                  <h5>Videos</h5>
                  <label>Description</label>
                  <div className="gap_p">
                    <div>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        multiline
                        rows={5}
                        type="text"
                        placeholder="Description"
                        fullWidth
                        variant="outlined"
                      />
                    </div>
                    <div className="upload_document v2 w_50">
                      <label htmlFor="icon-picture1">
                        <div className="inner">
                          <AddIcon />
                          <Input
                            id="icon-picture1"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                          />
                        </div>
                      </label>
                    </div>
                    <div className="upload_document v2 w_50">
                      <label htmlFor="icon-picture2">
                        <div className="inner">
                          <AddIcon />
                          <Input
                            id="icon-picture2"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form_btn">
                  <SecondaryButton
                    content="Save"
                    onClick={handleOpen_successModal}
                  />
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </section>

        {/* Success Modal */}
        <Modal
          className="modal success_modal"
          open={open_successModal}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open_successModal}>
            <Box className="modalBox_body">
              <Box>
                <Button
                  className="close_btn"
                  onClick={handleClose_successModal}
                >
                  <CloseIcon />
                </Button>
                <figure>
                  <img src="../../images/success-tick.svg" alt="" />
                </figure>
                <h2>Awesome !</h2>
                <p>
                  "Admin will approve your property request in 2 to 3 business
                  days.
                </p>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </main>
    </Layout>
  );
};

export default OwnerTransferUploadPictureAndVideo;
