import React from "react";
import "../../auth/auth.scss";
import Layout from "../../../layout";
import UploadDocunmentContent from "./features/uploadDocument";

const UploadDocunment = () => {
  return (
    <Layout>
      <main>
        <UploadDocunmentContent />
      </main>
    </Layout>
  );
};

export default UploadDocunment;
