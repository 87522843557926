import { END_POINTS } from "../helpers";
import { Blog, CmsData, FaqData, News, Report } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetCmsResponse = {
  data: CmsData | null;
  statusCode: number;
  message: string;
};

type PostContactUsBody = {
  email: string;
  phone: string;
  name: string;
  message: string;
  countryCode: string;
  subject: string;
};

type BlogRes = {
  blogsCount: number;
  blogs: Blog[];
};

type NewsRes = {
  newsCount: number;
  news: News[];
};

export type CountryRes = {
  uniqueCountries: string[];
};

export type AboutUsRes = {
  team: {
    Designation: string;
    name: string;
    _id: string;
    imagePath: string;
  }[];
};

export const commonApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFaqList: builder.query<CommonResponseType & { data: FaqData[] }, {}>({
      query: () => ({
        url: END_POINTS.get_faq,
        method: "GET",
      }),
    }),
    getCmsList: builder.query<GetCmsResponse, {}>({
      query: () => ({
        url: END_POINTS.get_cms,
        method: "GET",
      }),
    }),
    postQuery: builder.mutation<CommonResponseType, PostContactUsBody>({
      query: (body) => ({
        url: END_POINTS.add_query,
        method: "POST",
        body,
      }),
    }),
    getAllListedCountry: builder.query<
      CommonResponseType & { data: { uniqueCountries: string[] } },
      {}
    >({
      query: () => ({
        url: END_POINTS.getAllListedCountry,
        method: "GET",
      }),
    }),
    getBlogs: builder.query<GetCmsResponse & { data: BlogRes }, {}>({
      query: () => ({
        url: END_POINTS.get_all_blog,
        method: "GET",
      }),
    }),
    getBlogById: builder.query<
      GetCmsResponse & { data: Blog[] },
      { blog_id: string | undefined }
    >({
      query: ({ blog_id }) => ({
        url: `${END_POINTS.getBlog_by_id}/${blog_id}`,
        method: "GET",
      }),
    }),
    getNews: builder.query<GetCmsResponse & { data: NewsRes }, {}>({
      query: () => ({
        url: END_POINTS.get_news,
        method: "GET",
      }),
    }),
    getNewsById: builder.query<
      GetCmsResponse & { data: News[] },
      { news_id: string | undefined }
    >({
      query: ({ news_id }) => ({
        url: `${END_POINTS.get_news_by_id}/${news_id}`,
        method: "GET",
      }),
    }),
    getAboutUs: builder.query<GetCmsResponse & { data: AboutUsRes[] }, {}>({
      query: () => ({
        url: `${END_POINTS.getAboutUs}`,
        method: "GET",
      }),
    }),
    getRating: builder.query<
      CommonResponseType & { data: { rating: number } },
      { property_id: string }
    >({
      query: ({ property_id }) => ({
        url: `${END_POINTS.getRating}/${property_id}`,
        method: "GET",
      }),
    }),
    postRating: builder.mutation<
      CommonResponseType & { data: { rating: number } },
      { property_id: string; body: { rating: number; review: string } }
    >({
      query: ({ body, property_id }) => ({
        url: `${END_POINTS.add_rating}/${property_id}`,
        method: "PUT",
        body,
      }),
    }),
    addReport: builder.mutation<
      CommonResponseType & { data: Report },
      { id: string | undefined; body: { message: string } }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.add_report}/${id}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});
export const {
  useLazyGetFaqListQuery,
  useLazyGetCmsListQuery,
  usePostQueryMutation,
  useLazyGetAllListedCountryQuery,
  useLazyGetBlogsQuery,
  useLazyGetBlogByIdQuery,
  useLazyGetNewsQuery,
  useLazyGetNewsByIdQuery,
  useLazyGetAboutUsQuery,
  usePostRatingMutation,
  useLazyGetRatingQuery,
  useAddReportMutation,
} = commonApi;
