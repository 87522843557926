import { SyntheticEvent, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import MyListedProperties from "./listedProperties";
import MyBoughtProperties from "./boughtProperties";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyPropertiesTab = () => {
  const [value, setValue] = useState(0);
  // const [valueType, setValueType] = useState(0);
  // const [getAllProp] = useLazyGetAllPropByInvestQuery();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // const getAllProperties = async (id: any) => {
  //   try {
  //     const response = await getAllProp({
  //       user_Id: id,
  //       investment_type: value === 0 ? "Profit Sharing" : "Ownership",
  //     }).unwrap();
  //     if (response?.statusCode === 200) {
  //       console.log(response, "rrr");
  //     }
  //   } catch (error: any) {
  //     errorToast(error?.data?.message || "");
  //   }
  // };
  // useEffect(() => {
  //   getAllProperties(userData?._id);
  // }, [valueType, userData]);

  // console.log(valueType, value, "val");

  return (
    <section className="myPropertiesTab_sc uht_spc">
      <div className="conta_iner">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="main_tab"
        >
          <Tab label="My Listed Properties" {...a11yProps(0)} />
          <Tab label="My Purchased Properties" {...a11yProps(1)} />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <MyListedProperties />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <MyBoughtProperties />
        </CustomTabPanel>
      </div>
    </section>
  );
};

export default MyPropertiesTab;
