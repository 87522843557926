import React from "react";
import "../../auth/auth.scss";
import Layout from "../../../layout";
import ChangePasswordContent from "./features/changePassword";

const ChangePassword = () => {
    return (
        <Layout>
            <main>
                <ChangePasswordContent />
            </main>
        </Layout>
    );
};

export default ChangePassword;
