/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { errorToast } from "../../../helpers";
import { useLazyGetNewsQuery } from "../../../service/Common";
import { News } from "../../../types/General";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

const HomeNews = () => {
  const navigate = useNavigate();
  const [getNews] = useLazyGetNewsQuery();
  const [news, setNews] = useState<News[]>([]);

  const getAllNews = async () => {
    try {
      const response = await getNews({}).unwrap();
      if (response?.statusCode === 200) {
        setNews(response?.data?.news);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
      console.log(error);
    }
  };

  useEffect(() => {
    getAllNews();
  }, []);

  return (
    <>
      {news?.length ? (
        <section className="news_sc u_spc">
          <div className="conta_iner">
            <div className="hd_1 mb_60">
              <h2>Our Recent News</h2>
            </div>
            <div className="news_list">
              {news?.length
                ? news?.slice(0, 2)?.map((item) => (
                    <article
                      className="single gap_m"
                      key={item?._id}
                      onClick={() => navigate(`/news-detail/${item?._id}`)}
                    >
                      <figure>
                        <img src={item?.newsImage || ""} alt="" />
                      </figure>
                      <div className="news_info">
                        <p className="c_secondary">
                          {moment(item?.createdAt).format("ll")}
                        </p>
                        <h3>{item?.title || ""}</h3>
                        <p style={{ wordWrap: "break-word" }}>
                          {item?.description?.length >= 360
                            ? item?.description?.slice(0, 360) + "...."
                            : item?.description}
                        </p>
                      </div>
                    </article>
                  ))
                : null}

              {/* <article className="single gap_m">
            <figure>
              <img src="/images/news-article-02.png" alt="" />
            </figure>
            <div className="news_info">
              <p className="c_secondary">27-09-2023 | 9:00PM</p>
              <h3>How Blockchain is Democratizing the Real Estate Market</h3>
              <p>
                In an age of streaming services, ride-hailing apps, and digital
                goods being assigned more value than ever, a myth arose that
                people no longer want to own physical assets. As we live our
                lives increasingly online and spend more on services, it’s clear
                how we spend our money has changed. As much as younger
                generations might appear to value doing things over having
                things, there's more to the story of ownership, in particular
                home ownership, than frivolous spending habits
              </p>
            </div>
          </article>
          <article className="single gap_m">
            <figure>
              <img src="../../images/news-article-03.jpg" alt="" />
            </figure>
            <div className="news_info">
              <p className="c_secondary">27-09-2023 | 9:00PM</p>
              <h3>The Advantage Of Tokenized Real Estate</h3>
              <p>
                One of the most immediate benefits of tokenization is that it
                massively lowers the barrier to entry, making the real estate
                market more accessible to millions of new investors.
                Traditionally, the only way to invest in real estate was to buy
                a property outright, something that is impossible for
                lower-income earners. Fractionalization reduces the upfront
                investment cost, with platforms like MetaRealEstate enabling
                people to invest as little as a few dollars into various hotels,
                apartments and luxury rental homes.
              </p>
            </div>
          </article> */}
            </div>
          </div>
          {news?.length > 2 ? (
            <div className="text_center" style={{ marginTop: 50 }}>
              <Link to="/news-listing" className="btn_link">
                See All
              </Link>
            </div>
          ) : null}
        </section>
      ) : null}
    </>
  );
};

export default HomeNews;
