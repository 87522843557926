import Layout from "../../layout";
import "../Properties/propertyListing.scss";
import PropertyLocationListing from "./features/propertyLocation";

const PropertyLocation = () => {
  return (
    <Layout>
      <main>
        <PropertyLocationListing />
      </main>
    </Layout>
  );
};

export default PropertyLocation;
