/* eslint-disable react-hooks/exhaustive-deps */
import Layout from "../../layout";
import "./transactions.scss";
import TotalTransactions from "./features/totalTransactions";
import TransactionsHistory from "./features/transactionsHistory";
import {
  useLazyGetTransactionQuery,
  useLazyTransactionHistoryQuery,
} from "../../service/Property";
import { useEffect, useState } from "react";
import { Transaction, TransactionRes } from "../../types/General";
import { errorToast } from "../../helpers";

const Transactions = () => {
  const [Transaction] = useLazyGetTransactionQuery();
  const [TransactionHistory] = useLazyTransactionHistoryQuery();

  const [transactions, setTransactions] = useState<Transaction>();
  const [transactionsHistory, setTransactionsHistory] = useState<
    TransactionRes[]
  >([]);

  const getTransaction = async () => {
    try {
      const res = await Transaction({}).unwrap();
      if (res?.statusCode === 200) {
        setTransactions(res?.data);
      }
    } catch (error: any) {
      errorToast(error?.message || "");
    }
  };

  const getTransactionHistory = async () => {
    try {
      const res = await TransactionHistory({}).unwrap();
      if (res?.statusCode === 200) {
        setTransactionsHistory(res?.data?.transaction || []);
      }
    } catch (error: any) {
      errorToast(error?.message || "");
    }
  };

  useEffect(() => {
    getTransaction();
    getTransactionHistory();
  }, []);

  return (
    <Layout>
      <main>
        <section className="transactions_sc uht_spc">
          <div className="conta_iner">
            <h2>Total Transactions</h2>
            <TotalTransactions transactions={transactions} />

            <h2>Transactions History</h2>
            <TransactionsHistory transactionsHistory={transactionsHistory} />
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Transactions;
