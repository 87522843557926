export const API_URL =
  "https://realestatephase1api.appgrowthcompany.com/api/v1/user"; // development

export const END_POINTS = {
  checkWalletAddress: "checkWalletAddress",
  signUp: "signUp",
  login: "login",
  mediaUpload: "upload",
  verify_otp: "VerifyOtp",
  update_profile: "updateProfile",
  get_profile: "getProfile",
  forgotPassword: "forgotPassword",
  resetPassword: "resetPassword",
  changePassword: "changePassword",
  logout: "logout",
  update_profile_by_id: "updateProfileById",

  //property
  get_all_property: "getAllProperties",
  getAllPropertiesWithCountry: "getAllPropertiesWithCountry",
  add_property: "listPropertyProfitSharing",
  update_profitSharing: "updateProperty",
  get_property_byId: "getPropertyById",
  get_prop_by_inves: "getListedPropertyByUser",
  getPurchasedPropertiesByUser: "getPurchasedPropertiesByUser",
  add_owner_property: "listPropertyOwnership",
  add_fav: "/makePropertyFavourite",
  get_fav_prop: "getFavouriteProperties",
  get_property_by_city: "getPropertyByCity",
  getAllListedCountry: "getAllListedCountry",
  getTransactionBYUser: "getTransactionBYUser",
  transactionHistory: "transactionHistory",
  userDividendByProperty: "userDividend",
  buy_token: "buyToken",
  claimProfit: "claimProfit",
  soldTokens: "soldTokens",
  setTokensOnSell: "setTokensOnSell",

  get_cms: "getCms",
  get_faq: "getFaq",
  add_query: "createSupportTicket",
  get_all_blog: "getBlogs",
  getBlog_by_id: "getBlogById",
  get_news: "getNews",
  get_news_by_id: "getNewsById",
  getAboutUs: "getAboutUs",
  add_rating: "propertyRating",
  getRating: "getRating",
  add_report: "report",
};
