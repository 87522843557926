type props = {
  content: string;
  onClick?: () => void;
  disabled?: boolean;
};
const PrimaryButton = ({ content, onClick, disabled }: props) => {
  return (
    <button
      type="submit"
      className="btn btn_primary"
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </button>
  );
};

export default PrimaryButton;
