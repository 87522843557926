import React from "react";
import "../../auth/auth.scss";
import Layout from "../../../layout";
import ResetPasswordContent from "./features/resetPassword";

const ResetPassword = () => {
    return (
        <Layout>
            <main>
                <ResetPasswordContent />
            </main>
        </Layout>
    );
};

export default ResetPassword;
