const HomeNewEra = () => {
  return (
    <section className="newEra_sc uht_spc ub_spc">
      <div className="conta_iner">
        <div className="gap_p aic">
          <div className="left_sc hd_1">
            <h2>A New ERA in Home Buying is Upon Us</h2>
            <p>Pay, close, and automate your transaction</p>
          </div>
          <div className="right_sc">
            <ul className="gap_m">
              <li>
                <figure>
                  <img
                    src="../../images/icon_transaction_platform.svg"
                    alt=""
                  />
                </figure>
                <h3>Transaction Platform</h3>
                <p>Exchange properties 24/7 around the globe.</p>
              </li>
              <li>
                <figure>
                  <img src="../../images/icon_title_escrow.svg" alt="" />
                </figure>
                <h3>Title Recording</h3>
                <p>Quickly and securely close with confidence.</p>
              </li>
              <li>
                <figure>
                  <img
                    src="../../images/icon_crypto_certifications.svg"
                    alt=""
                  />
                </figure>
                <h3>Fractionalize Property</h3>
                <p>Sell your property in fractions to global users</p>
              </li>
              <li>
                <figure>
                  <img src="../../images/icon_boost.svg" alt="" />
                </figure>
                <h3>Get Started Today</h3>
                <p>Complete a quick form and let's get started</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeNewEra;
