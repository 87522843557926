import React, { Dispatch, SetStateAction } from "react";
import { Button, TextField } from "@mui/material";
import { SecondaryButton } from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import { isNumber, isString } from "../../utils/validation";

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  formik: any;
};

const LoanDetails = ({ setOpen, formik }: Props) => {
  return (
    <>
      <div className="flex_head">
        <h2>Loan Details (if any)</h2>
        <Button className="close_btn" onClick={() => setOpen(false)}>
          <CloseIcon />
        </Button>
      </div>
      <div className="form_control">
        <div className="flex_label">
          <label>Loan Details(If any)</label>
        </div>
        <div className="gap_p">
          <div>
            <label>Bank Name</label>
            <TextField
              hiddenLabel
              type="text"
              placeholder="Bank Name"
              variant="outlined"
              fullWidth
              name="bankName"
              id="bankName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bankName}
              helperText={formik.touched.bankName && formik.errors.bankName}
            />
          </div>
          <div>
            <label>Loan Type</label>
            <TextField
              hiddenLabel
              type="text"
              placeholder="Loan Type"
              variant="outlined"
              fullWidth
              name="loanType"
              id="loanType"
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else if (isString(val.target.value)) {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              value={formik.values.loanType}
              helperText={formik.touched.loanType && formik.errors.loanType}
            />
          </div>
          <div>
            <label>Amount</label>
            <TextField
              hiddenLabel
              type="text"
              placeholder="Amount"
              variant="outlined"
              fullWidth
              name="amount"
              id="amount"
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else if (isNumber(val.target.value)) {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              value={formik.values.amount}
              helperText={formik.touched.amount && formik.errors.amount}
            />
          </div>
          <div>
            <label>Paid Amount</label>
            <TextField
              hiddenLabel
              type="text"
              placeholder="Paid Amount"
              variant="outlined"
              fullWidth
              name="paidAmount"
              id="paidAmount"
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else if (isNumber(val.target.value)) {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              value={formik.values.paidAmount}
              helperText={formik.touched.paidAmount && formik.errors.paidAmount}
            />
          </div>
        </div>
      </div>
      <div className="form_btn">
        <SecondaryButton content="Save" onClick={() => setOpen(false)} />
      </div>
    </>
  );
};

export default LoanDetails;
