import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { SecondaryButton } from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setDishWasher: Dispatch<SetStateAction<string>>;
  setDryer: Dispatch<SetStateAction<string>>;
  setIceMaker: Dispatch<SetStateAction<string>>;
  dishwasher: string;
  setMicrowave: Dispatch<SetStateAction<string>>;
  dryer: string;
  setOven: Dispatch<SetStateAction<string>>;
  iceMaker: string;
  setRefrigerator: Dispatch<SetStateAction<string>>;
  setWasher: Dispatch<SetStateAction<string>>;
  washer: string;
  setHeating: Dispatch<SetStateAction<string>>;
  heating: string;
  microwave: string;
  refrigerator: string;
  oven: string;
  setExteriorFeature: Dispatch<SetStateAction<string[]>>;
  exteriorFeature: string[];
  setParkingFeature: Dispatch<SetStateAction<string[]>>;
  parkingFeature: string[];
};

const Appliances = ({
  setOpen,
  dishwasher,
  refrigerator,
  oven,
  dryer,
  setOven,
  iceMaker,
  setRefrigerator,
  setWasher,
  washer,
  setHeating,
  heating,
  microwave,
  setDishWasher,
  setDryer,
  setIceMaker,
  setMicrowave,
  setExteriorFeature,
  exteriorFeature,
  setParkingFeature,
  parkingFeature,
}: Props) => {
  const [parking, setParking] = useState("");
  const [exterior, setExterior] = useState("");

  const handleExterior = () => {
    if (exterior) {
      setExteriorFeature((prevFeatures: any) => [...prevFeatures, exterior]);
    }
    setExterior("");
  };

  const handleParking = () => {
    if (parking) {
      setParkingFeature((prevFeatures: any) => [...prevFeatures, parking]);
    }
    setParking("");
  };

  const handleDeleteExteriorFeature = (index: number) => {
    setExteriorFeature((prevFeatures: any) =>
      prevFeatures.filter((_: any, i: any) => i !== index)
    );
  };

  const handleDeleteParkingFeature = (index: number) => {
    setParkingFeature((prevFeatures: any) =>
      prevFeatures.filter((_: any, i: any) => i !== index)
    );
  };
  return (
    <>
      <div className="flex_head">
        <h2>Appliances</h2>
        <Button className="close_btn" onClick={() => setOpen(false)}>
          <CloseIcon />
        </Button>
      </div>
      <div className="form_control">
        <label>Dishwasher</label>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={dishwasher}
          onChange={(event: SelectChangeEvent) => {
            setDishWasher(event.target.value as string);
          }}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </div>
      <div className="form_control">
        <label>Dryer</label>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={dryer}
          onChange={(event: SelectChangeEvent) => {
            setDryer(event.target.value as string);
          }}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </div>
      <div className="form_control">
        <label>Ice Maker</label>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={iceMaker}
          onChange={(event: SelectChangeEvent) => {
            setIceMaker(event.target.value as string);
          }}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </div>
      <div className="form_control">
        <label>Microwave</label>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={microwave}
          onChange={(event: SelectChangeEvent) => {
            setMicrowave(event.target.value as string);
          }}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </div>
      <div className="form_control">
        <label>Oven</label>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={oven}
          onChange={(event: SelectChangeEvent) => {
            setOven(event.target.value as string);
          }}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </div>
      <div className="form_control">
        <label>Refrigerator</label>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={refrigerator}
          onChange={(event: SelectChangeEvent) => {
            setRefrigerator(event.target.value as string);
          }}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </div>
      <div className="form_control">
        <label>Washer</label>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={washer}
          onChange={(event: SelectChangeEvent) => {
            setWasher(event.target.value as string);
          }}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </div>
      <div className="form_control">
        <label>Heating: Central Furnace</label>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={heating}
          onChange={(event: SelectChangeEvent) => {
            setHeating(event.target.value as string);
          }}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </div>
      <div className="form_control">
        <label>
          <h6>Exterior and lot features</h6>
        </label>
        <ul className="value_list">
          {exteriorFeature?.map((item: string, i: number) => (
            <li key={i}>
              {item}
              <CloseIcon onClick={() => handleDeleteExteriorFeature(i)} />
            </li>
          ))}
        </ul>

        <div className="flex">
          <TextField
            hiddenLabel
            type="text"
            placeholder="Exterior and lot features"
            variant="outlined"
            fullWidth
            value={exterior}
            onChange={(e) => setExterior(e.target.value)}
          />
          <AddIcon onClick={handleExterior} />
        </div>
      </div>

      <div className="form_control">
        <label>
          <h6>Parking features</h6>
        </label>

        <ul className="value_list">
          {parkingFeature?.map((item: string, i: number) => (
            <li key={i}>
              {item}
              <CloseIcon onClick={() => handleDeleteParkingFeature(i)} />
            </li>
          ))}
        </ul>

        <div className="flex">
          <TextField
            hiddenLabel
            type="text"
            placeholder="Parking features"
            variant="outlined"
            fullWidth
            value={parking}
            onChange={(e) => setParking(e.target.value)}
          />
          <AddIcon onClick={handleParking} />
        </div>
      </div>
      <div className="form_btn">
        <SecondaryButton content="Save" onClick={() => setOpen(false)} />
      </div>
    </>
  );
};

export default Appliances;
