/* eslint-disable react-hooks/exhaustive-deps */
import "../blog.scss";
import Layout from "../../../layout";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Blog } from "../../../types/General";
import { useLazyGetBlogsQuery } from "../../../service/Common";
import { Loader, errorToast } from "../../../helpers";
import moment from "moment";

const BlogListing = () => {
  const navigate = useNavigate();
  const [getBlog, { isLoading }] = useLazyGetBlogsQuery();
  const [blogs, setBlogs] = useState<Blog[]>([]);

  const getAllBlog = async () => {
    try {
      const response = await getBlog({}).unwrap();
      if (response?.statusCode === 200) {
        setBlogs(response?.data?.blogs);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBlog();
  }, []);

  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <main className="blogListing_page">
        <section className="blogListing_sc">
          <div className="conta_iner">
            <div className="head">
              <h1>Blogs</h1>
            </div>

            <div className="blogs_list gap_m">
              {blogs?.length ? (
                blogs?.map((item) => (
                  <article
                    className="single"
                    key={item?._id}
                    onClick={() => navigate(`/blog-detail/${item?._id}`)}
                  >
                    <figure>
                      <img src={item?.blogImages || ""} alt="" />
                    </figure>
                    <div className="blog_info">
                      <p className="rounded">
                        {moment(item?.createdAt).format("ll")}
                      </p>
                      <h3>{item?.title || ""}</h3>
                      <p style={{ wordWrap: "break-word" }}>
                        {item?.description?.length >= 180
                          ? item?.description?.slice(0, 180) + "...."
                          : item?.description}
                      </p>
                      <span
                        className="btn_link"
                        onClick={() => navigate(`/blog-detail/${item?._id}`)}
                      >
                        Read full article
                      </span>
                    </div>
                  </article>
                ))
              ) : (
                <p>No blogs found</p>
              )}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default BlogListing;
