import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SecondaryButton from "../../../../components/button/secondaryButton";
import { Input } from "@mui/material";
import { UploadMedia } from "../../../../utils/mediaUpload";
import { Loader, errorToast } from "../../../../helpers";
import { EmbarkComponent } from "../../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { usePutUpdateProfileByIdMutation } from "../../../../service/Auth";
import CloseIcon from "@mui/icons-material/Close";

const UploadDocunmentContent = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [updateProfileMethod, { isLoading }] =
    usePutUpdateProfileByIdMutation();

  const [frontImg, setFrontImg] = useState<string>("");
  const [backImg, setBackImg] = useState<string>("");
  const [doctype, setDocType] = useState("PASSPORT");
  const [error, setError] = useState(false);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      if (key === "front") {
        setFrontImg(res?.data);
      } else {
        setBackImg(res?.data);
      }
    } else {
      errorToast(res?.message);
    }
  };

  const handleSignUp = async () => {
    if (!frontImg || !backImg) {
      setError(true);
      return;
    }
    setError(false);
    let body = {
      document: doctype,
      documentFrontImage: frontImg,
      documentBackImage: backImg,
    };
    let data = {
      email: state?.email,
      screenType: "signUp",
    };
    try {
      const response = await updateProfileMethod({
        userId: state?.id,
        body,
      }).unwrap();
      if (response?.statusCode === 200) {
        navigate("/verification", { state: data });
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  return (
    <section className="auth_page">
      <Loader isLoad={isLoading} />
      <div className="side_container">
        <div className="gap_p">
          <EmbarkComponent />
          <div className="right_side">
            <h2>Upload your document for KYC</h2>
            <div className="form_control">
              <RadioGroup
                aria-labelledby="demo-document-label"
                defaultValue="valid-passport"
                name="document"
              >
                <FormControlLabel
                  className="label_checkbox"
                  value="valid-passport"
                  control={<Radio />}
                  label="Valid Passport"
                  onChange={() => setDocType("PASSPORT")}
                />
                <FormControlLabel
                  className="label_checkbox"
                  value="driving-licences"
                  control={<Radio />}
                  label="Your Driving Licences"
                  onChange={() => setDocType("DRIVING_LICENSE")}
                />
                <FormControlLabel
                  className="label_checkbox"
                  value="voter-id"
                  control={<Radio />}
                  label="Your Voter Id"
                  onChange={() => setDocType("VOTER_ID")}
                />
              </RadioGroup>
            </div>
            <div className="form_control">
              <div className="gap_p">
                <div className="upload_document w_50">
                  {frontImg ? (
                    <div className="inner">
                      <div className="cross_icn">
                        <CloseIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            setFrontImg("");
                          }}
                        />
                      </div>

                      <figure>
                        <img src={frontImg} alt="" />
                      </figure>
                    </div>
                  ) : (
                    <label htmlFor="icon-button-file">
                      <div className="inner">
                        <span>
                          <img src="../../images/icon_upload.svg" alt="" />
                          Drag & Drop your files here
                        </span>
                        <Input
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleImageUpload(e, "front")
                          }
                        />
                      </div>

                      {error && !frontImg ? (
                        <h6 className="err_msg">This field is required</h6>
                      ) : null}
                    </label>
                  )}
                </div>
                <div className="upload_document w_50">
                  {backImg ? (
                    <div className="inner">
                      <div className="cross_icn">
                        <CloseIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            setBackImg("");
                          }}
                        />
                      </div>

                      <figure>
                        <img src={backImg} alt="" />
                      </figure>
                    </div>
                  ) : (
                    <label htmlFor="icon-button-file2">
                      <div className="inner">
                        <span>
                          <img src="../../images/icon_upload.svg" alt="" />
                          Drag & Drop your files here
                        </span>
                        <Input
                          id="icon-button-file2"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleImageUpload(e, "back")
                          }
                        />
                      </div>

                      {error && !backImg ? (
                        <h6 className="err_msg">This field is required</h6>
                      ) : null}
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className="form_btn">
              <SecondaryButton content="Next" onClick={() => handleSignUp()} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UploadDocunmentContent;
