import React from "react";
import "./home.scss";
import Layout from "../../layout";
import HomeHero from "./features/hero";
import HomeMarquee from "./features/marquee";
import HomeAbout from "./features/about";
import HomeProperties from "./features/properties";
import HomeNewEra from "./features/newEra";
import HomeFavoritedProperties from "./features/favoriteProperties";
import HomeFaq from "./features/faq";
import HomeNews from "./features/news";
import HomeBecomeSeller from "./features/becomeSeller";
import HomeBlog from "./features/blog";

const LandingPage = () => {
  return (
    <Layout>
      <main className="home_page">
        <HomeHero />
        <HomeMarquee />
        <HomeAbout />
        <HomeProperties />
        <HomeNewEra />
        <HomeFavoritedProperties />
        <HomeFaq />
        <HomeNews />
        <HomeBecomeSeller />
        <HomeBlog />
      </main>
    </Layout>
  );
};

export default LandingPage;
