import { Checkbox, FormControlLabel, FormGroup, Slider } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { SecondaryButton } from "../../../components";
import CloseIcon from "@mui/icons-material/Close";
import { GetPropertyDetail } from "../../../types/General";

type props = {
  isDrawerActive: boolean;
  setDrawerIsActive: Dispatch<SetStateAction<boolean>>;
  propertyDetail: GetPropertyDetail[];
  setSelectedValues: Dispatch<SetStateAction<string[]>>;
  selectedValues: string[];
  setSliderValue: Dispatch<SetStateAction<number | string>>;
  sliderValue: number | string;
  setSliderValueBathroom: Dispatch<SetStateAction<number | string>>;
  sliderValueBathroom: number | string;
  sliderValueProperty: string[];
  setSliderValueProperty: Dispatch<SetStateAction<string[]>>;
  getAllCountry: string[];
};

const FilterDrawer = ({
  setDrawerIsActive,
  isDrawerActive,
  propertyDetail,
  setSelectedValues,
  selectedValues,
  sliderValue,
  setSliderValue,
  sliderValueBathroom,
  setSliderValueBathroom,
  setSliderValueProperty,
  sliderValueProperty,
  getAllCountry,
}: props) => {
  const handleReset = () => {
    if (
      sliderValueBathroom ||
      sliderValueProperty?.length ||
      selectedValues?.length ||
      sliderValue
    ) {
      setSliderValueBathroom("");
      setSliderValue("");
      setSelectedValues([]);
      setSliderValueProperty([]);
    }
  };
  // Function to handle checkbox change
  const handleCheckboxChange = (event: any) => {
    const { value } = event.target;
    if (event.target.checked) {
      // If checkbox is checked, add the value to selectedValues
      setSelectedValues([...selectedValues, value]);
    } else {
      // If checkbox is unchecked, remove the value from selectedValues
      setSelectedValues(selectedValues.filter((item) => item !== value));
    }
  };

  // Function to handle checkbox change
  const handlePropertyTypeChange = (event: any) => {
    const { value } = event.target;
    if (event.target.checked) {
      // If checkbox is checked, add the value to selectedValues
      setSliderValueProperty([...sliderValueProperty, value]);
    } else {
      // If checkbox is unchecked, remove the value from selectedValues
      setSliderValueProperty(
        sliderValueProperty.filter((item) => item !== value)
      );
    }
  };

  // Handle the slider value change
  const handleSliderChange = (event: any, newValue: number) => {
    setSliderValue(newValue);
  };

  // Handle the slider value change
  const handleSliderChangeBathroom = (event: any, newValue: number) => {
    setSliderValueBathroom(newValue);
  };

  const Propertytype = ["Residential", "Commercial", "Private"];
  return (
    <aside
      className={isDrawerActive ? "propertyFilter active" : "propertyFilter"}
    >
      <div className="inner">
        <span
          className="overlay"
          onClick={() => setDrawerIsActive(!isDrawerActive)}
        ></span>
        <div className="filter_form">
          <div className="filter_head">
            <span
              className="close_btn"
              onClick={() => setDrawerIsActive(!isDrawerActive)}
            >
              <CloseIcon />
            </span>
            <h2>Filter</h2>
            <button className="reset_btn" onClick={handleReset}>
              Reset
            </button>
          </div>
          <div className="filter_body">
            <div className="form_control">
              <label>Location</label>
              <FormGroup>
                {getAllCountry?.length
                  ? getAllCountry?.map((item, i) => (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            checked={selectedValues.includes(item)}
                            onChange={handleCheckboxChange}
                            value={item}
                          />
                        }
                        label={item}
                      />
                    ))
                  : null}
              </FormGroup>
            </div>
            <div className="form_control">
              <label>Bedroom</label>
              <Slider
                value={Number(sliderValue)}
                onChange={(e, newValue) =>
                  handleSliderChange(e, newValue as number)
                }
                defaultValue={0}
                aria-label="Default"
                valueLabelDisplay="auto"
              />
            </div>
            <div className="form_control">
              <label>Bathroom</label>
              <Slider
                value={Number(sliderValueBathroom)}
                onChange={(e, newValue) =>
                  handleSliderChangeBathroom(e, newValue as number)
                }
                defaultValue={0}
                aria-label="Default"
                valueLabelDisplay="auto"
              />
            </div>
            <div className="form_control">
              <label>Property Type</label>
              <FormGroup>
                {Propertytype?.length
                  ? Propertytype?.map((item, i) => (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            checked={sliderValueProperty.includes(item)}
                            onChange={handlePropertyTypeChange}
                            value={item}
                          />
                        }
                        label={item}
                      />
                    ))
                  : null}
                {/* <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Residential"
                />
                <FormControlLabel control={<Checkbox />} label="Commercial" />
                <FormControlLabel control={<Checkbox />} label="Private" /> */}
              </FormGroup>
            </div>
            <div className="form_btn">
              <SecondaryButton
                content="Search"
                onClick={() => setDrawerIsActive(!isDrawerActive)}
              />
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default FilterDrawer;
