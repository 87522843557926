/* eslint-disable react-hooks/exhaustive-deps */
// import "../blog.scss";
import Layout from "../../../layout";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Loader, errorToast } from "../../../helpers";
import { News } from "../../../types/General";
import { useLazyGetNewsByIdQuery } from "../../../service/Common";
import moment from "moment";

const NewsDetail = () => {
  const { id } = useParams();
  const [newsDetail, setNewsDetail] = useState<News>();
  const [NewsDetailMutation, { isLoading }] = useLazyGetNewsByIdQuery();

  const getBlogDetail = async () => {
    try {
      const response = await NewsDetailMutation({
        news_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setNewsDetail(response?.data[0]);
      }
    } catch (error: any) {
      console.log(error, "errror");
      errorToast(error?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      getBlogDetail();
    }
  }, []);

  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <main className="blogDetail_page">
        <section className="blogDetail_sc ut_spc">
          <div className="conta_iner">
            <div className="inner">
              <figure className="blog_figure">
                <img src={newsDetail?.newsImage || ""} alt="" />
              </figure>
              <p className="rounded">
                {moment(newsDetail?.createdAt).format("ll")}
              </p>
              <h1>{newsDetail?.title || ""}</h1>
              <div className="blog_desc">
                <p>{newsDetail?.description || ""}</p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default NewsDetail;
