import TextField from "@mui/material/TextField";
import { isString } from "../../../utils/validation";
import { useEffect, useState } from "react";
import { useGetAllPropertyMutation } from "../../../service/Property";
import { GetPropertyDetail } from "../../../types/General";
import { useNavigate } from "react-router-dom";
import { showWarning } from "../../../helpers";

const HomeHero = () => {
  const navigate = useNavigate();
  const [getPropertyByName] = useGetAllPropertyMutation();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [getProperty, setGetProperty] = useState<GetPropertyDetail[]>([]);
  const [metamask, setMetamask] = useState<any>();
  const [chainId, setChainId] = useState<string>();

  useEffect(() => {
    if (window.ethereum) {
      setMetamask(window.ethereum);
      setChainId(window.ethereum.networkVersion);
    } else {
      console.log("meta mask not detected");
    }
  }, []);

  const getAllPropertiesData = async () => {
    try {
      const res = await getPropertyByName({
        search: debouncedSearchTerm.trim(),
        bedroom: "",
        bathrooms: "",
        location: [],
        propertyType: [],
      }).unwrap();

      if (res?.statusCode === 200) {
        console.log(res, "res");
        setGetProperty(res?.data?.property || []);
        // setGetAllProducts(res?.data || []);
        // setSearchResults(res?.recordsFiltered || 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm?.length) {
      getAllPropertiesData();
    } else {
      setGetProperty([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleNavigate = (id: string) => {
    if (metamask && chainId === "80001") {
      setDebouncedSearchTerm("");
      setSearchTerm("");
      setGetProperty([]);
      navigate(`/property-detail/${id}`);
    } else {
      showWarning("Please Connect to mumbai Testnet");
    }
  };

  return (
    <section className="hero_sc">
      <div className="conta_iner">
        <div className="hero_left">
          <h1>
            Tokenizing and Trading real estate is easy with{" "}
            <span title="Web 3">Web3</span>
          </h1>
          <p>
            Transact easier, faster & more securely with blockestate using
            blockchain technology
          </p>
          <div className="form">
            <div className="form_control">
              <TextField
                className="search_field"
                hiddenLabel
                placeholder="Search"
                fullWidth
                name="search"
                type="text"
                variant="outlined"
                value={searchTerm}
                onChange={(val: any) => {
                  if (isString(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              {getProperty?.length || debouncedSearchTerm?.length ? (
                <div className="product_srch">
                  {getProperty?.length ? (
                    getProperty?.map((item) => {
                      return (
                        <ul key={item?._id}>
                          <li
                            className="main_li"
                            onClick={() => handleNavigate(item?._id)}

                            // onClick={() => {
                            // setDebouncedSearchTerm("");
                            // setSearchTerm("");
                            // setGetProperty([]);
                            //   navigate(`/property-detail/${item?._id}`);
                            // }}
                          >
                            <div className="li_left">
                              {item?.propertyImages?.length
                                ? item?.propertyImages
                                    ?.slice(0, 1)
                                    .map((val: any, i) => {
                                      return (
                                        <figure key={i}>
                                          <img src={val} alt="Property" />
                                        </figure>
                                      );
                                    })
                                : ""}
                            </div>
                            <div className="li_ryt">
                              <div className="stock_flex">
                                <h3>{item?.propertyTitle || ""}</h3>
                                <p>Type: {item?.investmentType || "0"}</p>
                              </div>
                              {/* <p>{item?.product_category?.name || ""}</p> */}
                            </div>
                          </li>
                        </ul>
                      );
                    })
                  ) : (
                    <h3 className="no_record">No Property Found</h3>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
