import React from "react";
import "../../addProperty/property.scss";
import Layout from "../../../layout";
import TextField from "@mui/material/TextField";
import { SecondaryButton } from "../../../components";
import PrimaryButton from "../../../components/button/primaryButton";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { Input } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const OwnershipTransferPropertyDetail = () => {
  const [open_gallery, setOpen_gallery] = React.useState(false);
  const handleOpen_gallery = () => setOpen_gallery(true);
  const handleClose_gallery = () => setOpen_gallery(false);

  const [open_milestone, setOpen_milestone] = React.useState(false);
  const handleOpen_milestone = () => setOpen_milestone(true);
  const handleClose_milestone = () => setOpen_milestone(false);

  return (
    <Layout>
      <main>
        <section className="propertyDetail_sc u_spc">
          <div className="conta_iner">
            <div className="property_gallery">
              <div className="gap_p">
                <div className="main_gallery">
                  <figure>
                    <img src="../../images/property-detail-01.jpg" alt="" />
                    <span className="tag">Ownership</span>
                  </figure>
                </div>
                <div className="other_gallery">
                  <figure>
                    <img src="../../images/property-detail-02.jpg" alt="" />
                  </figure>
                  <figure>
                    <img src="../../images/property-image-01.jpg" alt="" />
                    <span className="more" onClick={handleOpen_gallery}>
                      <img src="../../images/icon_image.svg" alt="" /> +4 Show
                      all photos
                    </span>
                  </figure>
                </div>
              </div>
            </div>
            <div className="property_description">
              <div className="gap_p">
                <div className="left_side">
                  <h2>Luxury villa in Range Park</h2>
                  <ul>
                    <li>
                      <img src="../../images/icon_bed.svg" alt="" /> 3 Bed
                    </li>
                    <li>
                      <img src="../../images/icon_bath.svg" alt="" /> 2 Bath
                    </li>
                    <li>
                      <img src="../../images/icon_kitchen.svg" alt="" /> 1
                      Kitchen
                    </li>
                  </ul>
                  <h3>Description</h3>
                  <p>
                    This incredible property is currently at the framing stage
                    of an impressive transformation. What was once a modest 1
                    level, 2-bedroom, 1-bath residence is being converted into a
                    sprawling 5-bedroom, 3.5-bath oasis. The major structural
                    work, including framing, electrical rough, plumbing rough,
                    insulation, and HVAC, has already been expertly completed by
                    skilled professionals. With permits and plans fully
                    approved, all that awaits is a visionary buyer who can bring
                    their personal touch to finish what has been started. This
                    presents an extraordinary opportunity for those with a keen
                    eye for design and a desire to create their dream home from
                    the ground up. With the possibility of utilizing a 203k
                    renovation loan, the path to completing this masterpiece is
                    made even more accessible. Whether you're a seasoned
                    investor looking to add value to a property or a homeowner
                    seeking a unique chance to customize your home exactly to
                    your tastes, this property is calling your name. Situated in
                    a up and coming neighborhood this home offers both
                    convenience and potential for a comfortable and contemporary
                    lifestyle. Nearby amenities, schools, parks, and easy access
                    to major routes further enhance the appeal of this
                    neighborhood. Do not miss this once-in-a-lifetime
                    opportunity to seize the reins and bring your vision to
                    life. Schedule a showing today and be prepared to envision
                    the unlimited potential that awaits at 1362 Graymont. Act
                    fast, as this rare gem won't stay on the market for long!
                  </p>
                </div>
                <div className="right_side">
                  <div className="box">
                    <button className="box_edit" onClick={handleOpen_milestone}>
                      <img src="../../images/icon_edit.svg" alt="" />
                    </button>
                    <h4>Luxury villa in Range Park</h4>
                    <p>USDT 240,485</p>
                    <ul>
                      <li>
                        <span>Milestone 1 (Upfront)</span>
                        <span>USDT 285.67</span>
                      </li>
                      <li>
                        <span>Milestone 2 (Contract Signing)</span>
                        <span>USDT 285.67</span>
                      </li>
                      <li>
                        <span>Milestone 3 (Contract Transfer)</span>
                        <span>USDT 285.67</span>
                      </li>
                    </ul>
                    <p className="status">Approval Pending</p>
                    <PrimaryButton content="Set On sale" />
                  </div>
                </div>
              </div>
            </div>
            <div className="property_boxes">
              <div className="gap_m">
                <div className="box_group w_100" style={{ display: "none" }}>
                  <h3>Your Milestone</h3>
                  <div className="box">
                    <table className="simple_table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Price</th>
                          <th>Event</th>
                          <th>Source</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>08/23/2023</td>
                          <td>USDT 1,800,000</td>
                          <td>Done</td>
                          <td>Upfront Amount</td>
                        </tr>
                        <tr>
                          <td>08/23/2023</td>
                          <td>USDT 1,800,000</td>
                          <td>Done</td>
                          <td>Milestone 1</td>
                        </tr>
                        <tr>
                          <td>08/23/2023</td>
                          <td>USDT 1,800,000</td>
                          <td>Pending</td>
                          <td>Milestone 2</td>
                        </tr>
                        <tr>
                          <td>08/23/2023</td>
                          <td>USDT 1,800,000</td>
                          <td>Pending</td>
                          <td>Milestone 3</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="box_group w_100" style={{ display: "none" }}>
                  <h3>Download and upload document</h3>
                  <div className="Uploadbox_document">
                    <div className="upload_document">
                      <div className="inner">
                        <figure>
                          <img src="../../images/news-article-02.jpg" alt="" />
                          <CloudDownloadIcon />
                        </figure>
                        <span className="close">
                          <CloseIcon />
                        </span>
                      </div>
                    </div>
                    <div className="upload_document">
                      <label htmlFor="icon-picture2">
                        <div className="inner">
                          <AddIcon />
                          <Input
                            id="icon-picture2"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <h3>Property Details</h3>
                  <ul className="list_data">
                    <li>
                      <span>Property Type</span>
                      <span className="c_secondary">Private Property</span>
                    </li>
                  </ul>
                </div>
                <div className="box">
                  <h3>Amount</h3>
                  <ul className="list_data">
                    <li>
                      <span>Milestone 1 (Upfront)</span>
                      <span className="c_secondary">USDT 285.67</span>
                    </li>
                    <li>
                      <span>Milestone 2 (Contract Signing)</span>
                      <span>
                        <b>USDT</b> 285.67
                      </span>
                    </li>
                    <li>
                      <span>Milestone 3 (Contract Transfer)</span>
                      <span>
                        <b>USDT</b> 285.67
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="box">
                  <h3>Cash and Financing</h3>
                  <p className="price_label">
                    Offering Breakdown <strong>USDT 240,485</strong>
                  </p>
                  <ul className="gap_p">
                    <li>
                      <strong>Contract Price</strong>
                      <span>USDT 163,300</span>
                    </li>
                    <li>
                      <strong>Closing Cost</strong>
                      <span>USDT 1,191</span>
                    </li>
                    <li>
                      <strong>Cash Reserve</strong>
                      <span>USDT 4,390</span>
                    </li>
                    <li>
                      <strong>Improvement Costs</strong>
                      <span>USDT 57,750</span>
                    </li>
                    <li>
                      <strong>Due Diligence</strong>
                      <span>USDT 685</span>
                    </li>
                    <li>
                      <strong>Acquisition Fee</strong>
                      <span>USDT 13,169</span>
                    </li>
                  </ul>
                  <p className="price_label">
                    Cash Balance <strong>USDT 1,590.03</strong>
                  </p>
                  <h3>Financing</h3>
                  <div className="progress">
                    <div
                      className="progress_status"
                      style={{ width: "40%" }}
                    ></div>
                  </div>
                  <div className="progress_selector">
                    <div className="single">
                      <span></span>
                      <p>
                        <span>Equity</span>
                        <strong>USDT 96,194</strong>
                      </p>
                    </div>
                    <div className="single">
                      <span></span>
                      <p>
                        <span>Debt</span>
                        <strong>USDT 144,291</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="box_group">
                  <div className="box">
                    <h3>Blockchain Details</h3>
                    <ul className="list_data">
                      <li>
                        <span>Chain Name</span>
                        <span className="c_secondary">Eth/Matrix</span>
                      </li>
                      <li>
                        <span>Contact Address</span>
                        <span>0xxabx1122</span>
                      </li>
                      <li>
                        <span>Owner Wallet</span>
                        <span>0xxabx1122</span>
                      </li>
                    </ul>
                  </div>
                  <div className="box address_box">
                    <h3>Address</h3>
                    <p>125 Ted Turner Dr SW, Atlanta, GA 30303</p>
                    <div className="map">
                      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3317.36476008907!2d-84.39939902366092!3d33.751238933563066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f503789ca5cb03%3A0x7cf5c19acca6916f!2s125%20Ted%20Turner%20Dr%20SW%2C%20Atlanta%2C%20GA%2030313%2C%20USA!5e0!3m2!1sen!2sin!4v1694434132228!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className="box_group w_100">
                  <h3>Price History for 323 4th St NE</h3>
                  <div className="box">
                    <table className="simple_table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Price</th>
                          <th>Event</th>
                          <th>Source</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>08/23/2023</td>
                          <td>USDT 1,800,000</td>
                          <td>Listed For Sale</td>
                          <td>FMLS GA #7264926</td>
                        </tr>
                        <tr>
                          <td>08/23/2023</td>
                          <td>USDT 1,800,000</td>
                          <td>Sold</td>
                          <td>FMLS GA #7264926</td>
                        </tr>
                        <tr>
                          <td>08/23/2023</td>
                          <td>USDT 1,800,000</td>
                          <td>Sold</td>
                          <td>NA</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="box w_100">
                  <h3>Documents</h3>
                  <ul className="list_document">
                    <li>
                      <img src="../../images/icon_document.svg" alt="" />{" "}
                      Ownership Document
                    </li>
                    <li>
                      <img src="../../images/icon_document.svg" alt="" />
                      Ownership Document
                    </li>
                    <li>
                      <img src="../../images/icon_document.svg" alt="" />
                      Ownership Document
                    </li>
                  </ul>
                </div>
                <div className="box w_100">
                  <h3>Interior features</h3>
                  <table>
                    <tbody>
                      <tr>
                        <th>Builtin Features</th>
                        <td></td>
                        <th>Separate Formal Dining Room</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Bedroom Main Level</th>
                        <td></td>
                        <th>Dual Sinks</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Breakfast Area</th>
                        <td></td>
                        <th>Elevator</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Dining Area</th>
                        <td></td>
                        <th>First Floor Entry</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>High Ceilings</th>
                        <td></td>
                        <th>Separate Shower</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Kitchen Island</th>
                        <td></td>
                        <th>Upper Level Primary</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Pantry</th>
                        <td></td>
                        <th>Bar</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Sitting Area Primary</th>
                        <td></td>
                        <th>Walk In Closets</th>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="box w_100">
                  <h3>Appliances</h3>
                  <table>
                    <tbody>
                      <tr>
                        <th>Dishwasher</th>
                        <td></td>
                        <th>Oven</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Dryer</th>
                        <td></td>
                        <th>Range</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Ice Maker</th>
                        <td></td>
                        <th>Refrigerator</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Microwave</th>
                        <td></td>
                        <th>Washer</th>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="box w_100">
                  <h3>Exterior and lot features</h3>
                  <table>
                    <tbody>
                      <tr>
                        <th>Exterior features</th>
                        <td></td>
                        <th>Exterior features</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Exterior features</th>
                        <td></td>
                        <th>Exterior features</th>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="box w_100">
                  <h3>Parking features</h3>
                  <table>
                    <tbody>
                      <tr>
                        <th>Driveway</th>
                        <td></td>
                        <th>Garage</th>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="box w_100">
                  <h3>Building and Construction</h3>
                  <table>
                    <tbody>
                      <tr>
                        <th>Year built</th>
                        <td></td>
                        <th>Flooring</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Construction materials</th>
                        <td></td>
                        <th>Roof</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Flooring</th>
                        <td></td>
                        <th>Architectural style</th>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="box w_100">
                  <h3>Other</h3>
                  <table>
                    <tbody>
                      <tr>
                        <th>View</th>
                        <td></td>
                        <th>Waterfront features</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Waterfront</th>
                        <td></td>
                        <th>Accessibility features</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Waterfront features</th>
                        <td></td>
                        <th>Taxes</th>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Gallery */}
        <Modal
          className="modal gallery_modal"
          open={open_gallery}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open_gallery}>
            <Box className="modalBox_body">
              <Box>
                <Button className="close_btn" onClick={handleClose_gallery}>
                  <CloseIcon />
                </Button>
                <Swiper
                  className="location_swiper gallery_swiper"
                  modules={[Navigation, Pagination, Autoplay]}
                  spaceBetween={10}
                  slidesPerView={1}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  pagination={{
                    el: ".swiper-pagination",
                    dynamicBullets: true,
                    clickable: true,
                  }}
                  // autoplay={{
                  //     delay: 2500,
                  //     disableOnInteraction: false,
                  // }}
                  loop
                >
                  <div className="swiper_action">
                    <div className="swiper-button-prev">
                      <ArrowBackIcon />
                    </div>
                    <div className="swiper-pagination"></div>
                    <div className="swiper-button-next">
                      <ArrowForwardIcon />
                    </div>
                  </div>
                  <SwiperSlide>
                    <figure>
                      <img src="../../images/property-detail-01.jpg" alt="" />
                    </figure>
                  </SwiperSlide>
                  <SwiperSlide>
                    <figure>
                      <img src="../../images/property-detail-02.jpg" alt="" />
                    </figure>
                  </SwiperSlide>
                  <SwiperSlide>
                    <figure>
                      <img src="../../images/property-detail-01.jpg" alt="" />
                    </figure>
                  </SwiperSlide>
                  <SwiperSlide>
                    <figure>
                      <img src="../../images/property-detail-02.jpg" alt="" />
                    </figure>
                  </SwiperSlide>
                  <SwiperSlide>
                    <figure>
                      <img src="../../images/property-detail-01.jpg" alt="" />
                    </figure>
                  </SwiperSlide>
                  <SwiperSlide>
                    <figure>
                      <img src="../../images/property-detail-02.jpg" alt="" />
                    </figure>
                  </SwiperSlide>
                </Swiper>
              </Box>
            </Box>
          </Fade>
        </Modal>

        {/* Edit Milestone */}
        <Modal
          className="modal form_modal"
          open={open_milestone}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open_milestone}>
            <Box className="modalBox_body">
              <Box>
                <div className="flex_head">
                  <h2>Luxury villa in Range Park</h2>
                  <Button className="close_btn" onClick={handleClose_milestone}>
                    <CloseIcon />
                  </Button>
                </div>
                <form>
                  <div className="form_control">
                    <label>Total Amount</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Total Amount"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <h6>Milestones</h6>
                  <div className="form_control">
                    <label>Milestone 1 (%)</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Milestone 1 (%)"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>Milestone 2 (%)</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Milestone 2 (%)"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_control">
                    <label>Milestone 3 (%)</label>
                    <TextField
                      hiddenLabel
                      type="text"
                      placeholder="Milestone 3 (%)"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="form_btn">
                    <SecondaryButton content="Update" />
                  </div>
                </form>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </main>
    </Layout>
  );
};

export default OwnershipTransferPropertyDetail;
