import React, { useState } from "react";
import "../../auth/auth.scss";
import Layout from "../../../layout";
import { useNavigate } from "react-router-dom";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  Input,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { EmbarkComponent, SecondaryButton } from "../../../components";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useAddOwnershipPropertyMutation,
  useAddProfitPropertyMutation,
} from "../../../service/Property";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
} from "../../../helpers";
import {
  Appliances,
  Construction,
  Inferior,
  LoanDetails,
  Other,
  ProfitHistory,
} from "../../../features";
import { UploadMedia } from "../../../utils/mediaUpload";
import { isNumber, isString } from "../../../utils/validation";
import { ProfitHistoryBody } from "../../../types/General";
import useAuth from "../../../hooks/useAuth";

const AddPropertyInfo = () => {
  const userData = useAuth();
  const walletAddress = getFromStorage(STORAGE_KEYS.WalletAddress);
  const navigate = useNavigate();
  const [value, setValue] = useState("Profit Sharing");
  const [activeModal, setActiveModal] = useState(1);
  const [open, setOpen] = useState(false);

  //Interior
  const [sink, setSink] = useState("No");
  const [pantry, setPantry] = useState("No");
  const [sitting, setSitting] = useState("No");
  const [shower, setShower] = useState("No");
  const [bar, setBar] = useState("No");
  const [closets, setClosets] = useState("No");

  //applices
  const [dishwasher, setDishWasher] = useState("No");
  const [dryer, setDryer] = useState("No");
  const [iceMaker, setIceMaker] = useState("No");
  const [microwave, setMicrowave] = useState("No");
  const [oven, setOven] = useState("No");
  const [refrigerator, setRefrigerator] = useState("No");
  const [washer, setWasher] = useState("No");
  const [heating, setHeating] = useState("No");
  const [parkingFeature, setParkingFeature] = useState<string[]>([]);
  const [exteriorFeature, setExteriorFeature] = useState<string[]>([]);

  //profit
  const [profitDetail, setProfitDetail] = useState<ProfitHistoryBody[]>([]);

  //other
  const [tax, setTax] = useState("No");
  const [frontImg, setFrontImg] = useState<string>("");
  const [backImg, setBackImg] = useState<string>("");
  const [error, setError] = useState(false);
  const [profitDate, setProfitDate] = useState("");

  const [addProfitSharingProperty, { isLoading }] =
    useAddProfitPropertyMutation();
  const [addOwnerShipProperty, addOwnerShipPropertyData] =
    useAddOwnershipPropertyMutation();

  const [propertyType, setPropertyType] = useState("Private");

  const handlePropertyType = (event: SelectChangeEvent) => {
    setPropertyType(event.target.value as string);
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      if (key === "front") {
        setFrontImg(res?.data);
      } else {
        setBackImg(res?.data);
      }
    } else {
      errorToast(res?.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      description: "",
      noofToal: "",
      profitExpted: "",
      propertyValue: "",
      propertyLoc: "",
      propertyCity: "",
      propertyCountry: "",
      date: "",
      noOfRooms: "",
      propertytype: "",
      builtIn: "",
      bedroomMainLevel: "",
      breakfastArea: "",
      diningArea: "",
      bathroom: "",
      yearBuilt: "",
      constMate: "",
      flooring: "",
      roof: "",
      architectural: "",
      view: "",
      viewFront: "",
      viewFeature: "",
      viewAccessiblity: "",

      //ownership
      totalPropertyAmount: "",
      milestone1: "",
      milestone2: "",
      milestone3: "",
      profitDate: "",
      profitPrice: "",
      profitAddr: "",
      bankName: "",
      loanType: "",
      loanAmount: "",
      paidAmount: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("This field is required!"),
      description: Yup.string().required("This field is required!"),
      propertyValue: Yup.string().required("This field is required!"),
      propertyLoc: Yup.string().required("This field is required!"),
      propertyCity: Yup.string().required("This field is required!"),
      propertyCountry: Yup.string().required("This field is required!"),

      // date:
      //   value === "Profit Sharing"
      //     ? Yup.date()
      //         .typeError("Enter valid date.")
      //         .required("This field is required!")
      //     : Yup.string().notRequired(),
      noofToal:
        value === "Profit Sharing"
          ? Yup.string().required("This field is required!")
          : Yup.string().notRequired(),
      profitExpted:
        value === "Profit Sharing"
          ? Yup.string().required("This field is required!")
          : Yup.string().notRequired(),
      noOfRooms:
        value === "Profit Sharing"
          ? Yup.string().required("This field is required!")
          : Yup.string().notRequired(),

      //ownership
      totalPropertyAmount:
        value !== "Profit Sharing"
          ? Yup.string().required("This field is required!")
          : Yup.string().notRequired(),
      milestone1:
        value !== "Profit Sharing"
          ? Yup.string().required("This field is required!")
          : Yup.string().notRequired(),
      milestone2:
        value !== "Profit Sharing"
          ? Yup.string().required("This field is required!")
          : Yup.string().notRequired(),
      milestone3:
        value !== "Profit Sharing"
          ? Yup.string().required("This field is required!")
          : Yup.string().notRequired(),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      if (!profitDate) {
        return;
      }

      if (value === "Profit Sharing") {
        const body = {
          propertyTitle: formik.values.title,
          propertyDescription: formik.values.description,
          totalTokens: formik.values.noofToal,
          propertyType: propertyType,
          profitDistributionDate: profitDate.toString(),
          investmentType: "Profit Sharing",
          expectedProfit: formik.values.profitExpted,
          propertyPrice: formik.values.propertyValue,
          propertyAddress: formik.values.propertyLoc,
          city: formik.values.propertyCity,
          country: formik.values.propertyCountry,
          ownershipDocuments: [frontImg, backImg],
          bedroomsCount: Number(formik.values.noOfRooms),
          userWalletAddress: userData?.walletAddress,
          //interior
          BuiltInFeature: formik.values.builtIn,
          diningArea: formik.values.diningArea,
          bedroomMainLevel: formik.values.bedroomMainLevel,
          kitchenCount: Number(formik.values.breakfastArea),
          bathroomCount: Number(formik.values.bathroom),
          propertyLocation: {
            lat: -34.928567,
            lng: -56.168106,
          },
          dualSink: sink,
          pantry: pantry,
          sittingArea: sitting,
          Bar: bar,
          separateShower: shower,
          walkInClosets: closets,
          //appliances
          dishwasher: dishwasher,
          dryer: dryer,
          refrigerator: refrigerator,
          washingMachine: washer,
          microwave: microwave,
          Oven: oven,
          iceMaker: iceMaker,
          heatingCentralFurnace: heating,
          parkingFeatures: parkingFeature,
          exteriorLotFeatures: exteriorFeature,

          //construction
          YearBuilt: formik.values.yearBuilt,
          ContructionMeterial: formik.values.constMate,
          Flooring: formik.values.flooring,
          Roof: formik.values.roof,
          ArchitecturalStyle: formik.values.architectural,

          //other
          View: formik.values.view,
          WaterFront: formik.values.viewFront,
          WaterFrontFeature: formik.values.viewFeature,
          AccessibilityFeature: formik.values.viewAccessiblity,
          Taxes: tax,
        };
        try {
          const response = await addProfitSharingProperty(body).unwrap();
          if (response?.statusCode === 200) {
            navigate(`/upload-picture-video/${response?.data?._id}`, {
              state: value,
            });
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      } else {
        //owernership body
        const body_ownership = {
          propertyTitle: formik.values.title,
          propertyDescription: formik.values.description,
          propertyPrice: Number(formik.values.totalPropertyAmount),
          milestoneAmount1: formik.values.milestone1,
          milestoneAmount2: formik.values.milestone2,
          milestoneAmount3: formik.values.milestone3,
          propertyType: propertyType,
          investmentType: "Ownership",
          ownershipDocuments: [frontImg, backImg],
          profitHistory: profitDetail,
          propertyAddress: formik.values.propertyLoc,
          city: formik.values.propertyCity,
          country: formik.values.propertyCountry,
          bedroomsCount: Number(formik.values.noOfRooms),
          userWalletAddress: walletAddress,

          //bank details
          BankName: formik.values.bankName,
          LoanType: formik.values.loanType,
          amount: Number(formik.values.loanAmount),
          paidAmount: Number(formik.values.paidAmount),
          //interior
          BuiltInFeature: formik.values.builtIn,
          diningArea: formik.values.diningArea,
          bedroomMainLevel: formik.values.bedroomMainLevel,
          kitchenCount: Number(formik.values.breakfastArea),
          bathroomCount: Number(formik.values.bathroom),
          // propertyLocation: {
          //   lat: -34.928567,
          //   lng: -56.168106,
          // },
          dualSink: sink,
          pantry: pantry,
          sittingArea: sitting,
          Bar: bar,
          separateShower: shower,
          walkInClosets: closets,
          //appliances
          iceMaker: iceMaker,
          dishwasher: dishwasher,
          dryer: dryer,
          refrigerator: refrigerator,
          washingMachine: washer,
          microwave: microwave,
          Oven: oven,
          heatingCentralFurnace: heating,
          parkingFeatures: parkingFeature,
          exteriorLotFeatures: exteriorFeature,

          //construction
          YearBuilt: formik.values.yearBuilt,
          ContructionMeterial: formik.values.constMate,
          Flooring: formik.values.flooring,
          Roof: formik.values.roof,
          ArchitecturalStyle: formik.values.architectural,

          //other
          View: formik.values.view,
          WaterFront: formik.values.viewFront,
          WaterFrontFeature: formik.values.viewFeature,
          AccessibilityFeature: formik.values.viewAccessiblity,
          Taxes: tax,
        };
        try {
          const response = await addOwnerShipProperty(body_ownership).unwrap();
          if (response?.statusCode === 200) {
            console.log(response, "resss");
            navigate(`/upload-picture-video/${response?.data?._id}`, {
              state: value,
            });
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const handleModal = () => {
    switch (activeModal) {
      case 1:
        return (
          <Box>
            <Inferior
              setOpen={setOpen}
              formik={formik}
              setSink={setSink}
              sink={sink}
              setPantry={setPantry}
              pantry={pantry}
              sitting={sitting}
              setSitting={setSitting}
              setShower={setShower}
              shower={shower}
              setBar={setBar}
              bar={bar}
              setClosets={setClosets}
              closets={closets}
            />
          </Box>
        );
      case 2:
        return (
          <Box>
            <Appliances
              setOpen={setOpen}
              setDishWasher={setDishWasher}
              dishwasher={dishwasher}
              setDryer={setDryer}
              dryer={dryer}
              iceMaker={iceMaker}
              setIceMaker={setIceMaker}
              setMicrowave={setMicrowave}
              microwave={microwave}
              setOven={setOven}
              oven={oven}
              setRefrigerator={setRefrigerator}
              refrigerator={refrigerator}
              setWasher={setWasher}
              washer={washer}
              setHeating={setHeating}
              heating={heating}
              parkingFeature={parkingFeature}
              setParkingFeature={setParkingFeature}
              exteriorFeature={exteriorFeature}
              setExteriorFeature={setExteriorFeature}
            />
          </Box>
        );
      case 3:
        return (
          <Box>
            <Construction setOpen={setOpen} formik={formik} />
          </Box>
        );
      case 4:
        return (
          <Box>
            <Other
              setOpen={setOpen}
              formik={formik}
              setTax={setTax}
              tax={tax}
            />
          </Box>
        );
      case 5:
        return (
          <Box>
            <ProfitHistory
              setOpen={setOpen}
              formik={formik}
              profitDetail={profitDetail}
              setProfitDetail={setProfitDetail}
            />
          </Box>
        );
      case 6:
        return (
          <Box>
            <LoanDetails setOpen={setOpen} formik={formik} />
          </Box>
        );

      default:
        return null;
    }
  };

  const formatNumberWithCommas = (number: any) => {
    if (number > 0) {
      const formattedNumber = number.toLocaleString("en-IN");
      return `$${formattedNumber}`;
    }
    return "";
  };

  // Create a function to handle value change
  const handlePropertyValueChange = (event: any) => {
    const inputValue = event.target.value;
    // Remove non-numeric characters and format as a number
    const newValue = parseFloat(inputValue.replace(/[^\d.]/g, ""));
    // Check if the numericValue is NaN or a valid number
    if (!isNaN(newValue)) {
      formik.setFieldValue("propertyValue", newValue);
    } else {
      formik.setFieldValue("propertyValue", "");
    }
  };

  return (
    <Layout>
      <Loader isLoad={isLoading || addOwnerShipPropertyData?.isLoading} />
      <main>
        <section className="auth_page">
          <div className="side_container">
            <div className="gap_p">
              <EmbarkComponent />
              <div className="right_side">
                <h2>Add Property Info</h2>

                <form onSubmit={formik.handleSubmit}>
                  <div className="form_control">
                    <RadioGroup
                      className="flex"
                      aria-labelledby="demo-document-label"
                      defaultValue="Profit Sharing"
                      name="document"
                    >
                      <FormControlLabel
                        className="label_checkbox"
                        value={value}
                        onChange={() => setValue("Profit Sharing")}
                        control={
                          <Radio
                            checked={value === "Profit Sharing" ? true : false}
                          />
                        }
                        label="Profit Sharing"
                      />
                      {/* <FormControlLabel
                        className="label_checkbox"
                        value="Ownership"
                        onChange={() => setValue("Ownership")}
                        control={
                          <Radio
                            checked={value === "Ownership" ? true : false}
                          />
                        }
                        label="Ownership Transfer"
                      /> */}
                    </RadioGroup>
                  </div>
                  <div className="form_control">
                    <label>Title</label>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder="Title"
                      fullWidth
                      name="title"
                      variant="outlined"
                      id="title"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                      helperText={formik.touched.title && formik.errors.title}
                    />
                  </div>
                  <div className="form_control">
                    <label>Description</label>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder="Description"
                      fullWidth
                      multiline
                      minRows={1.2}
                      maxRows={4}
                      name="description"
                      variant="outlined"
                      id="description"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </div>
                  <div className="form_control">
                    <label>Property Value</label>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder="Property Value"
                      fullWidth
                      name="propertyValue"
                      variant="outlined"
                      id="propertyValue"
                      inputProps={{ maxLength: 25 }}
                      onChange={handlePropertyValueChange}
                      onBlur={formik.handleBlur}
                      // value={
                      //   Number(formik.values.propertyValue)
                      //     ? Number(formik.values.propertyValue.toFixed(2))
                      //     : ""
                      // }
                      // value={formik.values.propertyValue}
                      // value={Intl.NumberFormat("en-IN", {
                      //   style: "currency",
                      //   currency: "USD",
                      // }).format(Number(formik.values.propertyValue))}

                      value={
                        formik.values.propertyValue !== null
                          ? formatNumberWithCommas(formik.values.propertyValue)
                          : ""
                      }
                      helperText={
                        formik.touched.propertyValue &&
                        formik.errors.propertyValue
                      }
                    />
                    {/* {(formik.values.propertyValue !== "" &&
                      Number(formik.values.propertyValue) <=
                        Number(formik.values.noofToal)) ||
                    error ? (
                      <h6 className="err_msg">
                        Property value should be greater than no of tokens
                      </h6>
                    ) : null} */}

                    {error &&
                    Number(formik.values.propertyValue) <=
                      Number(formik.values.noofToal) ? (
                      <h6 className="err_msg">
                        Property value should be greater than no of tokens
                      </h6>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form_control">
                    <label>Property Address</label>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder="Property Address"
                      fullWidth
                      name="propertyLoc"
                      variant="outlined"
                      id="propertyLoc"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.propertyLoc}
                      helperText={
                        formik.touched.propertyLoc && formik.errors.propertyLoc
                      }
                    />
                  </div>
                  <div className="form_control">
                    <label>City</label>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder="City"
                      fullWidth
                      name="propertyCity"
                      variant="outlined"
                      id="propertyCity"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.propertyCity}
                      helperText={
                        formik.touched.propertyCity &&
                        formik.errors.propertyCity
                      }
                    />
                  </div>
                  <div className="form_control">
                    <label>Property Country</label>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder="Property Country"
                      fullWidth
                      name="propertyCountry"
                      variant="outlined"
                      id="propertyCountry"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.propertyCountry}
                      helperText={
                        formik.touched.propertyCountry &&
                        formik.errors.propertyCountry
                      }
                    />
                  </div>
                  {value === "Profit Sharing" ? (
                    <>
                      <div className="form_control">
                        <label>No. of Token</label>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type="text"
                          placeholder="No. of Tokens"
                          fullWidth
                          name="noofToal"
                          variant="outlined"
                          id="noofToal"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isNumber(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.noofToal}
                          helperText={
                            formik.touched.noofToal && formik.errors.noofToal
                          }
                        />
                      </div>
                      <div className="form_control">
                        <label>Profit Expected(USD)</label>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type="text"
                          placeholder="Profit Expected(USD)"
                          fullWidth
                          name="profitExpted"
                          variant="outlined"
                          id="profitExpted"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isNumber(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.profitExpted}
                          helperText={
                            formik.touched.profitExpted &&
                            formik.errors.profitExpted
                          }
                        />
                        {/* <p className="note">
                          Note: 2 months profit will be stacked for security
                          purposes. <Link to="#">Learn more</Link>
                        </p> */}
                      </div>
                      <div className="form_control">
                        <label>Date of profit distribution</label>
                        {/* <TextField
                          className="text_field"
                          hiddenLabel
                          type={"date"}
                          name="date"
                          variant="outlined"
                          fullWidth
                          id="date"
                          value={formik.values.date}
                          onChange={formik.handleChange}
                          helperText={formik.touched.date && formik.errors.date}
                        /> */}
                        <FormControl fullWidth>
                          <Select
                            // style={{ maxHeight: "20px" }}
                            className="dateSelect"
                            placeholder="select"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            displayEmpty
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            value={profitDate}
                            onChange={(event: SelectChangeEvent) =>
                              setProfitDate(event.target.value as string)
                            }
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            {Array.from({ length: 28 }, (_, index) => (
                              <MenuItem key={index + 1} value={index + 1}>
                                {index + 1}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {error && !profitDate ? (
                          <h6 className="err_msg">This field is required</h6>
                        ) : (
                          ""
                        )}
                        {/* <TextField
                          className="text_field"
                          hiddenLabel
                          placeholder="Date of profit distribution"
                          fullWidth
                          name="date"
                          variant="outlined"
                          type={"date"}
                          id="date"
                          value={formik.values.date || null}
                          onChange={formik.handleChange}
                          helperText={formik.touched.date && formik.errors.date}
                        /> */}
                      </div>
                      {/* <div className="form_control">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={formik.values.date || null}
                            defaultCalendarMonth={maxDate}
                            onChange={(date) => {
                              formik.setFieldValue(
                                "date",
                                moment(date.toISOString()).format("YYYY-MM-DD")
                              );
                            }}
                            className="date_picker"
                            InputProps={{ disableUnderline: true }}
                            maxDate={moment().subtract(18, "years")}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                variant="standard"
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "Date of birth",
                                  readOnly: true,
                                }}
                                error={Boolean(
                                  formik.touched.date && formik.errors.date
                                )}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div> */}

                      <div className="form_control">
                        <label>No. of Rooms</label>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type="text"
                          placeholder="No. of Rooms"
                          fullWidth
                          name="noOfRooms"
                          variant="outlined"
                          id="noOfRooms"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isNumber(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.noOfRooms}
                          helperText={
                            formik.touched.noOfRooms && formik.errors.noOfRooms
                          }
                        />
                      </div>
                      <div className="form_control">
                        <label>Type of Property</label>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={propertyType}
                          onChange={handlePropertyType}
                        >
                          <MenuItem value="Residential">Residential</MenuItem>
                          <MenuItem value="Commercial">Commercial</MenuItem>
                          <MenuItem value="Private">Private</MenuItem>
                        </Select>
                      </div>
                    </>
                  ) : (
                    <div>
                      {/* ownership */}
                      <div className="form_control">
                        <label>Total Amount of property</label>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type="text"
                          placeholder="Total Amount of property"
                          fullWidth
                          name="totalPropertyAmount"
                          variant="outlined"
                          id="totalPropertyAmount"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isNumber(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.totalPropertyAmount}
                          helperText={
                            formik.touched.totalPropertyAmount &&
                            formik.errors.totalPropertyAmount
                          }
                        />
                      </div>
                      <div className="form_control">
                        <label>Milestone 1 (Upfront Amount)</label>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type="text"
                          placeholder="Milestone 1 (Upfront Amount)"
                          fullWidth
                          name="milestone1"
                          variant="outlined"
                          id="milestone1"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isNumber(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.milestone1}
                          helperText={
                            formik.touched.milestone1 &&
                            formik.errors.milestone1
                          }
                        />
                      </div>
                      <div className="form_control">
                        <label>Milestone 2 (Contract Signing)</label>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type="text"
                          placeholder="Milestone 2 (Contract Signing)"
                          fullWidth
                          name="milestone2"
                          variant="outlined"
                          id="milestone2"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isNumber(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.milestone2}
                          helperText={
                            formik.touched.milestone2 &&
                            formik.errors.milestone2
                          }
                        />
                      </div>
                      <div className="form_control">
                        <label>Milestone 3 (Ownership contract minting)</label>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type="text"
                          placeholder="Milestone 3 (Ownership contract minting)"
                          fullWidth
                          name="milestone3"
                          variant="outlined"
                          id="milestone3"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isNumber(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.milestone3}
                          helperText={
                            formik.touched.milestone3 &&
                            formik.errors.milestone3
                          }
                        />
                      </div>
                      <div className="form_control">
                        <label>Type of Property</label>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={propertyType}
                          onChange={handlePropertyType}
                        >
                          <MenuItem value="Residential">Residential</MenuItem>
                          <MenuItem value="Commercial">Commercial</MenuItem>
                          <MenuItem value="Private">Private</MenuItem>
                        </Select>
                      </div>

                      {/* ownership */}
                    </div>
                  )}
                  <div className="form_control">
                    <label>
                      <h6>Upload Document</h6>
                    </label>
                    <div className="form_control">
                      <div className="gap_p">
                        <div className="upload_document w_50">
                          {frontImg ? (
                            <div className="inner">
                              <div className="cross_icn">
                                <CloseIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setFrontImg("");
                                  }}
                                />
                              </div>

                              <figure>
                                <img src={frontImg} alt="" />
                              </figure>
                            </div>
                          ) : (
                            <label htmlFor="icon-button-file">
                              <div className="inner">
                                <AddIcon />
                                <Input
                                  id="icon-button-file"
                                  type="file"
                                  inputProps={{
                                    accept: "image/png,image/jpeg",
                                  }}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => handleImageUpload(e, "front")}
                                />
                              </div>

                              {error && !frontImg ? (
                                <p className="err_msg">
                                  This field is required
                                </p>
                              ) : null}
                            </label>
                          )}
                        </div>
                        <div className="upload_document w_50">
                          {backImg ? (
                            <div className="inner">
                              <div className="cross_icn">
                                <CloseIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setBackImg("");
                                  }}
                                />
                              </div>

                              <figure>
                                <img src={backImg} alt="" />
                              </figure>
                            </div>
                          ) : (
                            <label htmlFor="icon-button-file2">
                              <div className="inner">
                                <AddIcon />
                                <Input
                                  id="icon-button-file2"
                                  type="file"
                                  inputProps={{
                                    accept: "image/png,image/jpeg",
                                  }}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => handleImageUpload(e, "back")}
                                />
                              </div>

                              {error && !backImg ? (
                                <p className="err_msg">
                                  This field is required
                                </p>
                              ) : null}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {value === "Profit Sharing" ? null : (
                    <>
                      <div className="form_control">
                        <span
                          className="modalClick"
                          onClick={() => {
                            setOpen(true);
                            setActiveModal(5);
                          }}
                        >
                          Profit History <ArrowForwardIosIcon />
                        </span>
                      </div>
                      <div className="form_control">
                        <span
                          className="modalClick"
                          onClick={() => {
                            setOpen(true);
                            setActiveModal(6);
                          }}
                        >
                          Loan Details (if any)
                          <ArrowForwardIosIcon />
                        </span>
                      </div>
                    </>
                  )}
                  <div className="form_control">
                    <span
                      className="modalClick"
                      onClick={() => {
                        setOpen(true);
                        setActiveModal(1);
                      }}
                    >
                      Interior features <ArrowForwardIosIcon />
                    </span>
                  </div>
                  <div className="form_control">
                    <span
                      className="modalClick"
                      onClick={() => {
                        setOpen(true);
                        setActiveModal(2);
                      }}
                    >
                      Appliances <ArrowForwardIosIcon />
                    </span>
                  </div>
                  <div className="form_control">
                    <span
                      className="modalClick"
                      onClick={() => {
                        setOpen(true);
                        setActiveModal(3);
                      }}
                    >
                      Building and Construction <ArrowForwardIosIcon />
                    </span>
                  </div>
                  <div className="form_control">
                    <span
                      className="modalClick"
                      onClick={() => {
                        setOpen(true);
                        setActiveModal(4);
                      }}
                    >
                      Other <ArrowForwardIosIcon />
                    </span>
                  </div>
                  <div className="form_btn">
                    <SecondaryButton
                      content="Next"
                      onClick={() => setError(true)}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Modal
          className="modal form_modal"
          open={open}
          onClose={() => setOpen(false)}
        >
          <Box className="modalBox_body">{handleModal()}</Box>
        </Modal>
      </main>
    </Layout>
  );
};

export default AddPropertyInfo;
