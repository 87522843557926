import { useState } from "react";
import TextField from "@mui/material/TextField";
import { EmbarkComponent, SecondaryButton } from "../../../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useResetPasswordMutation } from "../../../../service/Auth";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  removeFromStorage,
  successToast,
} from "../../../../helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { temporaryToken } from "../../../../reducers/authSlice";
import { useAppDispatch } from "../../../../hooks/store";

const ResetPasswordContent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state } = location;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .label("New password")
        .required("New password is required.")
        .min(6, "Password must be at least 6 characters.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Must contain 8 or more characters, one uppercase, one lowercase and one number. "
        ),

      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      let body = {
        key: state,
        password: formik.values.newPassword,
      };

      try {
        const response = await resetPassword(body).unwrap();
        if (response?.statusCode === 200) {
          successToast("Password updated successfully" || "");
          removeFromStorage(STORAGE_KEYS.tempToken);
          navigate("/login");
          dispatch(
            temporaryToken({
              tempToken: null,
            })
          );
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error, "errorrr");
      }
      formik.setSubmitting(false);
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl = document.getElementById("newPassword");
    if (inputEl) {
      setTimeout(() => {
        if (inputEl instanceof HTMLInputElement) {
          inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
        }
      }, 0);
    }
  };
  const handleshowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
    const inputEl = document.getElementById("passwordConfirmation");
    if (inputEl) {
      setTimeout(() => {
        if (inputEl instanceof HTMLInputElement) {
          inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
        }
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <section className="auth_page">
      <Loader isLoad={isLoading} />
      <div className="side_container">
        <div className="gap_p">
          <EmbarkComponent />
          <div className="right_side">
            <h2>Reset Account Password</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="form_control">
                <label>Enter a new password</label>
              </div>
              <div className="form_control">
                <TextField
                  className="text_field"
                  sx={{ m: 0 }}
                  hiddenLabel
                  placeholder="Password"
                  fullWidth
                  margin="normal"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  id="newPassword"
                  name="newPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className="eye_btn" position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form_control">
                <TextField
                  className="text_field"
                  sx={{ m: 0 }}
                  hiddenLabel
                  placeholder="Confirm Password"
                  fullWidth
                  margin="normal"
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordConfirmation}
                  helperText={
                    formik.touched.passwordConfirmation &&
                    formik.errors.passwordConfirmation
                  }
                  type={showConfirmPassword ? "text" : "password"}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className="eye_btn" position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleshowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form_btn">
                <SecondaryButton content="Reset Password" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPasswordContent;
