import Header from "./header";
import Footer from "./footer";
// import "./Layout.scss";

const Layout = ({ children }: { children: any }) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
